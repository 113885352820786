/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClearOutlined, SearchOutlined } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { Ref, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Key } from 'utils/keyCodes'

type Props = {
  onSearch: (search: string, reason?: 'EnterKeyPressed') => void
  timeout?: number
  disableTimeout?: boolean
  searchOnEnterOrBlur?: boolean
  textFieldRef?: Ref<SearchTextFieldRef>
  disableClearable?: boolean
} & Omit<TextFieldProps, 'ref'>

export type SearchTextFieldRef = {
  clear: () => void
  getValue: () => string
}

const SearchTextField = (props: Props, ref: Ref<SearchTextFieldRef>) => {
  const {
    onSearch,
    timeout,
    disableTimeout,
    textFieldRef: textFieldRef,
    defaultValue,
    searchOnEnterOrBlur,
    disableClearable,
    ...textFieldProps
  } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState(defaultValue as string)
  const searched = useRef(false)

  const searchTimeout = useRef(undefined)

  useImperativeHandle(ref, () => ({
    clear: () => setSearch(''),
    getValue: () => {
      return search
    },
  }))

  const handleSearchChange = (value: string, reason?: 'cleared') => {
    if (reason === 'cleared') {
      setSearch('')
      setTimeout(() => {
        onSearch(undefined)
      })
      searched.current = true
      return
    }

    if (props.disableTimeout) {
      setSearch(value)
      onSearch(isEmpty(value) ? undefined : value)
      searched.current = true
      return
    }

    if (props.searchOnEnterOrBlur) {
      setSearch(value)
      searched.current = false
      return
    }

    clearTimeout(searchTimeout.current)

    setSearch(value)

    searchTimeout.current = setTimeout(() => {
      onSearch(isEmpty(value) ? undefined : value)
      searched.current = true
    }, props.timeout ?? 1000)
  }

  return (
    <TextField
      {...textFieldProps}
      value={search || ''}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          e.preventDefault()
          if (!searched.current) {
            onSearch(isEmpty(search) ? undefined : search, 'EnterKeyPressed')
          }
        }
      }}
      onBlur={() => {
        if (props.searchOnEnterOrBlur && !searched.current) {
          onSearch(isEmpty(search) ? undefined : search)
        }
      }}
      onChange={(e) => handleSearchChange(e.target.value)}
      placeholder={t('common:search', 'search')}
      InputProps={{
        ...textFieldProps.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
        endAdornment: disableClearable ? null : (
          <InputAdornment position="end">
            <IconButton
              disabled={search === ''}
              onClick={() => {
                handleSearchChange('', 'cleared')
              }}
              size="small"
              color="secondary"
            >
              <ClearOutlined fontSize="small" color="secondary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

const SearchTextFieldWithRef = forwardRef<SearchTextFieldRef, Props>(
  SearchTextField
)

export default SearchTextFieldWithRef

// const MemoizedSearchField = React.memo(
//   forwardRef<SearchTextFieldRef, Props>(SearchTextField),
//   isEqual
// )

// export default MemoizedSearchField
