import { MoreVertOutlined } from '@mui/icons-material'
import {
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import classNames from 'classnames'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ExpirationDateProgressVariant } from 'features/BillOfMaterials/QuotationExpirationDate/ExpirationDateProgressVariant'
import { MoneyString } from 'model/Money'
import moment from 'moment'
import React, { CSSProperties, MouseEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProjectStateDto,
  ProjectSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useKanbanCardStyles } from './KanbanCard.styles'
import { LaneScrollContext } from './KanbanComponents/LaneScrollContext'

type CardProps = {
  showDeleteButton?: boolean
  onDelete?: () => void
  onClick?: (e: MouseEvent) => void
  style?: CSSProperties
  tagStyle?: CSSProperties
  className?: string
  id: string
  title: string
  label: string
  description: string
  tags: Array<string>
  loading: Record<string, boolean>
  setPriority: (
    laneId: ProjectStateDto,
    projectId: string,
    priority: number
  ) => void
  index: number
  status: ProjectStateDto
  metadata: ProjectSummaryDto
  laneId: ProjectStateDto
}

const statesToShowExpireDate: ProjectStateAsString[] = [
  ProjectStateAsString.CANCELLED,
  ProjectStateAsString.DEFINING,
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
  ProjectStateAsString.QUOTED,
  ProjectStateAsString.NEGOTIATING,
]

const KanbanMenu = (props: CardProps) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)

  const { classes } = useKanbanCardStyles()
  const { t } = useTranslation()

  const setPriority = (
    e: React.MouseEvent<HTMLLIElement>,
    projectId: string,
    priority: number
  ) => {
    e.preventDefault()
    e.stopPropagation()

    let status = props.laneId

    if (!status) {
      status = (props.metadata.status[0].toUpperCase() +
        props.metadata.status.slice(1)) as ProjectStateDto
    }

    props.setPriority(status, projectId, priority)
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{ position: 'absolute', right: 0, top: 0 }}
    >
      <IconButton
        ref={menuButtonRef}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setMenuOpen(true)
        }}
        color="inherit"
        size="small"
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        open={menuOpen}
        anchorEl={menuButtonRef.current}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem
          onClick={(e) => {
            setPriority(e, props.id, 0)
          }}
          className={classes['priority-0']}
        >
          <Typography variant="body1">
            {t('project:priority-0', 'not defined')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setPriority(e, props.id, 1)
          }}
          className={classes['priority-1']}
        >
          <Typography variant="body1">
            {t('project:priority-1', 'urgent')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setPriority(e, props.id, 2)
          }}
          className={classes['priority-2']}
        >
          <Typography variant="body1">
            {t('project:priority-2', 'important')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setPriority(e, props.id, 3)
          }}
          className={classes['priority-3']}
        >
          <Typography variant="body1">
            {t('project:priority-3', 'normal')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setPriority(e, props.id, 4)
          }}
          className={classes['priority-4']}
        >
          <Typography variant="body1">
            {t('project:priority-4', 'low')}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

const _KanbanProjectSummaryCard = (props: CardProps) => {
  const { classes } = useKanbanCardStyles()
  const { t } = useTranslation()

  const projectSummary = props.metadata

  const isSellerParty = useAppSelector(
    (state: RootAppState) => !state.user?.organizationContext?.isBuyingParty
  )

  const scrollIndex = React.useContext(LaneScrollContext) || {
    min: 0,
    max: Infinity,
  }

  if (props.index < scrollIndex.min || props.index > scrollIndex.max) {
    return null
  }

  return (
    <Card
      elevation={0}
      data-id={props.id}
      data-index={props.index}
      data-status={projectSummary.status}
      data-projectref={projectSummary.projectReference}
      style={props.style}
      className={classNames(
        classes.root,
        props.className,
        classes[`priority-${isSellerParty ? projectSummary.priority : 0}`]
      )}
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault()
          window.open(`/app/project/${projectSummary.id}`, '_blank')
          return
        }

        props.onClick(e)
      }}
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.preventDefault()
          window.open(`/app/project/${projectSummary.id}`, '_blank')
        }
      }}
    >
      <div
        style={{
          padding: '.5em 2.2rem 0 1em',
          display: 'flex',
          alignItems: 'baseline',
          position: 'relative',
          justifyContent: 'space-between',
        }}
        onClick={(e) => e.stopPropagation()}
        className={classes.header}
        onDoubleClick={props.onClick}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              gap: '2em',
              justifyContent: 'space-between',
              userSelect: 'none',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'pre-line',
                wordWrap: 'normal',
              }}
              title={props.title}
            >
              {props.title}
            </Typography>
            <Typography variant="body1">
              {MoneyString(projectSummary.salesPriceOfItems)}
            </Typography>
            <KanbanMenu {...props} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '2em',
              width: '100%',
            }}
          >
            <Typography
              variant="caption"
              title={props.label}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                width: '100%',
              }}
            >
              {props.label}
            </Typography>
            <Typography
              variant="caption"
              className={'kanban-grayText'}
              style={{ display: 'inline-block' }}
            >
              {moment(projectSummary.lastOperation).fromNow()}
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ flex: '1 1 auto' }}>
        <Divider />
        {projectSummary.quotationExpiresAt &&
          statesToShowExpireDate.includes(
            projectSummary.status.toUpperCase() as ProjectStateAsString
          ) && (
            <CardLabelValue
              label={t('common:expires')}
              value={
                <ExpirationDateProgressVariant
                  expirationDate={projectSummary.quotationExpiresAt}
                  disableLabel
                />
              }
              classes={{
                ...classes,
                value: classNames(classes.value, classes.rightAligned),
                valueLabelRoot: 'kanban-card-progress',
              }}
              style={{
                paddingLeft: '.3em',
                paddingRight: '4px',
                marginTop: '.5em',
                alignItems: 'baseline',
              }}
            />
          )}

        <LocalizedTypography
          translationKey="common:customer"
          variant="caption"
          className={'kanban-grayText'}
          sx={{ pl: 0.5 }}
        >
          customer
        </LocalizedTypography>
        <Typography
          variant="body1"
          sx={{
            pl: 2,
            pr: 2,
            fontWeight: 'bold',
            whiteSpace: 'pre-line',
            wordWrap: 'normal',
          }}
        >
          {projectSummary.buyingPartyName}
        </Typography>
        {
          projectSummary.contactPerson ? (
            <div className={classes.contentPadding}>
              <Typography variant="body2">
                {projectSummary.contactPerson?.firstName || ''}{' '}
                {projectSummary.contactPerson?.lastName || ''}
              </Typography>
              <Typography variant="caption">
                {projectSummary.contactPerson?.email || ''}
              </Typography>
            </div>
          ) : null
          // <Typography
          //   variant="caption"
          //
          //   style={{
          //     width: '100%',
          //     marginTop: '1rem',
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //   }}
          // >
          //   no contact person
          // </Typography>
        }
      </div>
      <div style={{ flex: '1 1 auto' }}>
        <Divider />

        <LocalizedTypography
          translationKey={'common:supplier'}
          variant="caption"
          className={'kanban-grayText'}
          sx={{ pl: 0.5 }}
        >
          supplier:
        </LocalizedTypography>
        <Typography variant="body1" sx={{ pl: 2, pr: 2, fontWeight: 'bold' }}>
          {projectSummary.sellingPartyName}
        </Typography>
        {
          projectSummary.salesPerson ? (
            <div className={classes.contentPadding}>
              <Typography variant="body2">
                {projectSummary.salesPerson.firstName}{' '}
                {projectSummary.salesPerson.lastName}
              </Typography>
              <Typography variant="caption">
                {projectSummary.salesPerson.email}
              </Typography>
            </div>
          ) : null
          // <Typography
          //   variant="caption"
          //
          //   style={{
          //     width: '100%',
          //     marginTop: '1rem',
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //   }}
          // >
          //   no sales person
          // </Typography>
        }
      </div>
      <div>
        <Divider />
        <CardLabelValue
          label={t('common:num-items')}
          value={projectSummary.numberOfItems}
          classes={{
            ...classes,
            value: classNames(classes.value, classes.rightAligned),
          }}
          style={{ paddingLeft: '.5em', paddingRight: '1em' }}
        />
      </div>
    </Card>
  )
}

// const getFirstNameLastName = (person: {
//   firstName?: string
//   lastName?: string
// }) => `${person?.firstName || ''} ${person?.lastName || ''}`.trim()

function CardLabelValue({
  label,
  value,
  classes,
  valueVariant,
  typographyProps,
  style,
}: {
  label?: string
  value: React.ReactNode
  classes: { label: string; value: string; valueLabelRoot: string }
  valueVariant?: Variant
  typographyProps?: TypographyProps
  style?: CSSProperties
}) {
  return (
    <div className={classes.valueLabelRoot} style={style}>
      <div className={classes.label}>
        <Typography
          variant="caption"
          color="inherit"
          {...(typographyProps || {})}
        >
          {label ? `${label}:` : ''}
        </Typography>
      </div>
      <div className={classes.value}>
        <Typography
          variant={valueVariant || 'caption'}
          color="inherit"
          {...(typographyProps || {})}
        >
          {value}
        </Typography>
      </div>
    </div>
  )
}

const KanbanProjectSummaryCard = React.memo(
  _KanbanProjectSummaryCard,
  (a, b) => a.id === b.id
)

export default KanbanProjectSummaryCard
