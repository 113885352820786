import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { LightTooltip } from './LightTooltip'
import { useDefiningStateItem } from './useDefiningStateItem'
import { useProjectStateItem } from './useProjectStateItem'

export const DefiningState = () => {
  const {
    getStateTranslationKey,
    getBaseClassName,
    changeStatus,
    SavingComponent,
  } = useProjectStateItem(ProjectStatus.DEFINING)

  const { canChangeToDefiningState, Errors, errorStyle } =
    useDefiningStateItem()

  const handleClick = () => {
    if (canChangeToDefiningState) {
      changeStatus(ProjectStateAsString.DEFINING)
    }
  }

  return (
    <LightTooltip title={canChangeToDefiningState ? '' : <Errors />}>
      <li
        style={!canChangeToDefiningState ? errorStyle : null}
        className={getBaseClassName}
        onClick={handleClick}
      >
        <LocalizedTypography
          translationKey={getStateTranslationKey()}
          variant="body2"
        />
        {/* <ErrorCountBadge errorCount={errorCount} /> */}
        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
