import { Theme, Tooltip } from '@mui/material'
import { withStyles } from 'tss-react/mui'

export const LightTooltip = withStyles(Tooltip, (theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    minWidth: '200px',
    padding: 0,
    color: theme.palette.text.primary,
    '& li': {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}))
