/* eslint-disable react-hooks/exhaustive-deps */
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { useResourceIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useResourceIdFilter'
import { PriceDetailsGraphData } from 'features/BomItemDetailsDrawer/useBomItemDetails'
import { useMemo, useRef } from 'react'
import {
  MoneyDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialsBarChart } from '../Graphs/MaterialsBarChart'
import { TotalsBarChart } from '../Graphs/TotalsBarChart'
import { WorkingStepsBarChart } from '../Graphs/WorkingStepsBarChart'

type Props = {
  priceSummaryKeys: string[]
  setPriceSummaryQuery: (description: string) => void
  isMaterialVisible: boolean
  isWorkingStepVisible: boolean
  priceScope: PriceScope
}

export type GraphData = {
  description: string
  category: string
  totalSalesPriceWorkingSteps: MoneyDto
  totalSalesPriceMaterials: MoneyDto
  workingStepPrices: PriceDetailsGraphData[]
  materialPrices: PriceDetailsGraphData[]
}

export const ProjectPriceSummaryGraph = (props: Props) => {
  const { handleChangeFilter: handleChangeResourceIdFilter } =
    useResourceIdFilter()
  const { handleChangeFilter: handleChangeArticleIdFilter } =
    useArticleIdFilter()

  const graphContainerRef = useRef<HTMLDivElement>()

  const scrollAndFilter = (price: PriceDetailsGraphData) => {
    if (price?.isWorkingStep) {
      handleChangeResourceIdFilter(price.workingStep?.resource?.id)
      handleChangeArticleIdFilter(undefined)
    } else if (price?.isMaterial) {
      handleChangeArticleIdFilter([price.materialId])
      handleChangeResourceIdFilter(undefined)
    }

    props.setPriceSummaryQuery(price.description)
  }

  const GraphComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (graphProps: { height?: number }) => {
      if (props?.isMaterialVisible && props?.isWorkingStepVisible) {
        return (
          <TotalsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      if (props?.isMaterialVisible) {
        return (
          <MaterialsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      if (props?.isWorkingStepVisible) {
        return (
          <WorkingStepsBarChart
            {...props}
            scrollAndFilter={scrollAndFilter}
            containerHeight={graphProps.height}
          />
        )
      }

      return null
    },
    [props?.isMaterialVisible, props?.isWorkingStepVisible]
  )

  return (
    <>
      <div ref={graphContainerRef}>
        <span>
          <GraphComponent height={204} />
        </span>
      </div>
    </>
  )
}
