import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useAppSelector } from 'store/configureStore'
import { LightTooltip } from './LightTooltip'
import { useProjectStateItem } from './useProjectStateItem'
import { useQuotingProjectState } from './useQuotingProjectState'

const projectState = ProjectStatus.QUOTING
const projectStateAsString = ProjectStateAsString.QUOTING

const visibleProjectStatus = [
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
  ProjectStateAsString.NEGOTIATING,
  ProjectStateAsString.QUOTED,
  ProjectStateAsString.ORDERED,
]
const waitingProjectStatus = [
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
]

export const QuotingState = () => {
  const {
    getStateTranslationKey,
    changeStatus,
    SavingComponent,
    getBaseClassName,
  } = useProjectStateItem(projectState)

  const {
    canChangeToQuotingState: canChangeToQuoteState,
    Errors,
    errorStyle,
    // errorCount,
  } = useQuotingProjectState()

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const stateTranslationKey = getStateTranslationKey()

  const handleClick = () => {
    if (canChangeToQuoteState) {
      changeStatus(ProjectStateAsString.QUOTING)
    }
  }

  if (isBuyerView) {
    return null
  }

  return (
    // <LightTooltip title={errorCount > 0 ? <Errors /> : ''}>
    <LightTooltip title={''}>
      <li
        style={{
          ...errorStyle,
          ...{ pointerEvents: isBuyerView ? 'none' : 'auto' },
        }}
        className={getBaseClassName}
        onClick={handleClick}
      >
        <LocalizedTypography
          translationKey={stateTranslationKey}
          variant="body2"
        />
        {/* <ErrorCountBadge errorCount={errorCount} /> */}

        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
