/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material'
import { AppLogo } from 'components/Layout/AppLogo'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { noop } from 'lodash'
import { useEffect } from 'react'
import { Debugger } from 'utils/debugger'
import { useAppLogin } from './useAppLogin'

export function NewLoginPage() {
  const {
    triedLoginWithRedirection,
    isLoading,
    error,
    logoSrc,
    hasPopupBlocker,
    appLoginWithPopup,
    appLoginWithRedirection,
    clearLoginTries,
    returnUrl,
  } = useAppLogin()

  useEffect(() => {
    return () => {
      if (!error) {
        console.info('Login cleanup: clearLoginTries')
        clearLoginTries()
      }
    }
  }, [clearLoginTries, error])

  useEffect(() => {
    if (!logoSrc) {
      return noop()
    }

    if (!triedLoginWithRedirection) {
      Debugger.Info(
        'routes: not tried login with redirection yet, returnUrl',
        returnUrl
      )
      appLoginWithRedirection()
    } else {
      if (!hasPopupBlocker) {
        appLoginWithPopup()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // appLoginWithPopup,
    // appLoginWithRedirection,
    hasPopupBlocker,
    logoSrc,
    triedLoginWithRedirection,
  ])

  useEffect(() => {
    if (!logoSrc && window.parent) {
      window.parent.postMessage({ type: 'RH24_EMBEDDED_SETUP_RETRY' }, '*')
    }
  }, [logoSrc])

  // eslint-disable-next-line no-console
  console.table({
    triedLoginWithRedirection,
    isLoading,
    error,
    logoSrc,
    hasPopupBlocker,
    returnUrl,
  })

  // if (!error && !hasPopupBlocker) {
  //   return <Spinning />
  // }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
          alignItems: 'center',
          maxWidth: (theme) => theme.breakpoints.values.sm,
        }}
      >
        <div style={{ textAlign: 'center', width: '100%' }}>
          <AppLogo style={{ maxWidth: '50%', display: 'inline-block' }} />
        </div>
        {isLoading && !triedLoginWithRedirection && !error && (
          <LocalizedTypography
            translationKey="common:loading"
            variant="body2"
          />
        )}
        {error && (
          <>
            <LocalizedTypography
              translationKey="common:login-error-title"
              variant="h6"
            >
              An error occurred during the login process
            </LocalizedTypography>
            <Typography variant="body1" gutterBottom>
              {error?.message}
            </Typography>
          </>
        )}
        {(hasPopupBlocker || triedLoginWithRedirection) && (
          <LocalizedTypography
            translationKey="common:login-error-popup-closed"
            variant="body1"
            gutterBottom
          >
            Due to a recent security update in your browser, the login process
            now requires a popup window. However, it appears that popups are
            currently blocked. To enable popups for this site, simply click on
            the button in your browser's URL bar that indicates popups have been
            blocked and select the option to "Always allow popups and redirects"
            for this website. Or click in the button bellow
          </LocalizedTypography>
        )}
        {triedLoginWithRedirection && (
          <>
            <LocalizedButton
              translationKey="common:login-error-popup-closed-button"
              defaultText="click here to open the login popup"
              style={{ width: '50%' }}
              onClick={() => {
                appLoginWithPopup()
              }}
              variant="contained"
            />
            <LocalizedButton
              translationKey="common:forgot-password-reload-page"
              defaultText="reload page"
              onClick={() => {
                clearLoginTries()
                window.location.reload()
              }}
              style={{ width: '50%' }}
              variant="contained"
            />
          </>
        )}
      </Box>
      {/* )} */}
    </div>
  )
}
