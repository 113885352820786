import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useAppSelector } from 'store/configureStore'
import { useProjectStateItem } from './useProjectStateItem'

export const ProductionStates = (props: { showStatus: ProjectStatus }) => {
  const {
    getStateTranslationKey,
    stateItemIsCurrent,
    getBaseClassName,
    SavingComponent,
    changeStatus,
  } = useProjectStateItem(props.showStatus)

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const updateProjectState = () => {
    if (!isBuyerView) {
      changeStatus(
        ProjectStateAsString[
          ProjectStatus[props.showStatus + (stateItemIsCurrent() ? 1 : 0)]
        ]
      )
    }
  }

  return (
    <li
      className={getBaseClassName}
      style={{ cursor: isBuyerView ? 'default' : 'pointer' }}
      onClick={updateProjectState}
      key={props.showStatus}
    >
      <LocalizedTypography
        translationKey={getStateTranslationKey()}
        variant="body2"
      />
      <SavingComponent />
    </li>
  )
}
