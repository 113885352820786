import { useTheme } from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { TargetPriceTooltip } from 'features/BomItemDetailsDrawer/Graph/Tooltips/TargetPriceTooltip'
import { PriceDetailsGraphData } from 'features/BomItemDetailsDrawer/useBomItemDetails'
import { isEqual } from 'lodash'
import { MultiplyMoney } from 'model/Money'
import { MouseEvent, useCallback, useRef } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  PriceScope,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { MoneyAxisTick } from '../../../Graph/YAxisTick'

const WorkingStepXAxisTick = (props: {
  x?: number
  y?: number
  onMouseEnter?: (e: MouseEvent, index: number) => void
  onMouseLeave?: (e: MouseEvent) => void
  onClick: (index: number) => void
  payload: {
    index: number
    value: {
      workingStepType: WorkingStepType
      machineName: string
    }
  }
}) => {
  const { payload } = props

  return (
    <g
      onClick={() => {
        props.onClick(payload.index)
      }}
      style={{ cursor: 'pointer' }}
    >
      <WorkingStepIconSvg
        workingStepType={payload.value.workingStepType}
        disableTooltip
        attributes={{
          width: 32,
          height: 32,
          x: props.x - 16,
          y: props.y - 8,
          textAnchor: 'end',
        }}
      />
    </g>
  )
}

export const WorkingStepsBarChart = (props: {
  priceSummaryKeys: Array<string>
  scrollAndFilter: (priceSummary: PriceDetailsGraphData) => void
  containerHeight?: number
}) => {
  const theme = useTheme()

  const priceSummariesSum: Array<PriceDetailsGraphData> = useAppSelector(
    (state: RootAppState) => {
      const graphData: Array<PriceDetailsGraphData> =
        props.priceSummaryKeys.reduce((acc, key) => {
          const priceSummary = state.project.priceSummaries[key]

          if (!priceSummary?.isWorkingStep) {
            return acc
          }

          acc.push({
            bomItemPointer: undefined,
            primaryWorkingStep: priceSummary.workingStep.primaryWorkingStep,
            secondaryWorkingStep: priceSummary.workingStep.secondaryWorkingStep,
            description: priceSummary.description,
            salesPrice: priceSummary.totalSalesPrice,
            mainActivityCostPrice: priceSummary.totalCostPrice,
            productionTime: priceSummary.productionTime,
            surchargeValue: priceSummary.surchargeValue,
            discountValue: MultiplyMoney(priceSummary.discountValue, -1),
            totalQuantity: priceSummary.totalQuantity,
            staggeredTotalQuantity: priceSummary.staggeredTotalQuantity,
            total: priceSummary.totalSalesPrice,
            totalSalesPrice: priceSummary.totalSalesPrice,
            totalCostPrice: priceSummary.totalCostPrice,
            resource: priceSummary.workingStep?.resource?.name,
            isMaterial: priceSummary.isMaterial,
            isWorkingStep: priceSummary.isWorkingStep,
            workingStep: priceSummary.workingStep,
          })

          return acc
        }, [] as Array<PriceDetailsGraphData>)

      graphData.length &&
        graphData.sort((a, b) => b.total?.value - a.total?.value)

      return graphData
    },
    isEqual
  )

  const activeDataKey = useRef<string | null>(null)
  const handleChangeDataKey = useCallback(
    (dataKey: keyof PriceDetailsGraphData) => {
      activeDataKey.current = dataKey
    },
    []
  )

  return (
    <ResponsiveContainer width={'100%'} height={props.containerHeight || 204}>
      <BarChart data={priceSummariesSum}>
        <Tooltip
          cursor={false}
          content={(tooltipProps) => {
            return (
              <TargetPriceTooltip
                {...tooltipProps}
                activeDataKey={activeDataKey.current}
                priceScope={PriceScope.Total}
              />
            )
          }}
          wrapperStyle={{
            zIndex: 1000,
          }}
          offset={40}
          isAnimationActive={false}
        />
        <defs>
          <pattern
            id="pattern-stripe"
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="1"
              height="4"
              transform="translate(0,0)"
              fill="white"
            ></rect>
          </pattern>
          <mask id="mask-stripe">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#pattern-stripe)"
            />
          </mask>
        </defs>
        {/* <Tooltip /> */}
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis
          dataKey="primaryWorkingStep"
          type="category"
          interval={0}
          tickMargin={12}
          height={42}
          tick={(_props) => {
            return (
              <WorkingStepXAxisTick
                x={_props.x}
                y={_props.y}
                payload={{
                  index: _props.index,
                  value: {
                    workingStepType: _props.payload.value,
                    machineName: priceSummariesSum[_props.index].resource,
                  },
                }}
                onClick={(index) => {
                  props.scrollAndFilter(priceSummariesSum[index])
                }}
              />
            )
          }}
        />
        <YAxis
          type="number"
          tick={<MoneyAxisTick defaultMoney={priceSummariesSum[0].total} />}
          ticks={priceSummariesSum.map((x) => x.total?.value)}
          minTickGap={10}
          interval={'preserveStartEnd'}
          allowDataOverflow={false}
          domain={[0, 'dataMax']}
        />
        <Bar
          dataKey={'totalCostPrice.value'}
          fill={theme.palette.info.dark}
          onMouseEnter={() => {
            handleChangeDataKey('totalCostPrice')
          }}
          onMouseLeave={() => handleChangeDataKey(undefined)}
          barSize={32}
          onClick={(data) => props.scrollAndFilter(data)}
          stackId="a"
          style={{
            cursor: 'pointer',
          }}
        />
        <Bar
          dataKey={'surchargeValue.value'}
          fill={theme.palette.success.main}
          onMouseEnter={() => handleChangeDataKey('surchargeValue')}
          onMouseLeave={() => handleChangeDataKey(undefined)}
          barSize={32}
          onClick={(data) => props.scrollAndFilter(data)}
          stackId="a"
          style={{
            cursor: 'pointer',
          }}
        />
        <Bar
          dataKey={'discountValue.value'}
          fill={theme.palette.error.main}
          mask="url(#mask-stripe)"
          stroke={theme.palette.common.black}
          strokeWidth={2}
          onMouseEnter={() => handleChangeDataKey('discountValue')}
          onMouseLeave={() => handleChangeDataKey(undefined)}
          barSize={32}
          onClick={(data) => props.scrollAndFilter(data)}
          stackId="a"
          style={{
            cursor: 'pointer',
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
