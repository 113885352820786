/* eslint-disable tss-unused-classes/unused-classes */
import { alpha, CircularProgress, getOverlayAlpha, Theme } from '@mui/material'
import {
  ProjectStateAsString,
  ProjectStatus,
  ProjectStatusLabelAfterCurrentState,
  ProjectStatusLabelBeforeCurrentState,
  ProjectStatusLabelDuringCurrentState,
} from 'controllers/Project/ProjectStateMachine'
import { saveProjectStatus } from 'features/BillOfMaterials/store/asyncActions/saveProjectStatus'
import { projectOperationSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => {
  const overlayAlpha = getOverlayAlpha(1)
  const backgroundColor = theme.palette.background.paper
  let backgroundImage = 'none'

  if (theme.palette.mode === 'dark') {
    // when we have the dark theme, an overlay is introduced by MUI
    // so the color background.paper is not the same as used in the Card component.
    // since this component sits on top of a Card, we need to use the same logic
    // to create the overlay, so the end color will be the same of the card.
    backgroundImage = `linear-gradient(${alpha(
      theme.palette.common.white,
      +overlayAlpha
    )},${alpha(theme.palette.common.white, +overlayAlpha)})`
  }

  return {
    // order: {
    //   // animation: 'gradient 2s linear infinite',
    //   backgroundColor: 'none',
    // },
    next: {
      position: 'relative',
      backgroundColor: theme.palette.info.main,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      zIndex: 10,
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        borderTopLeftRadius: '2px',
        clipPath:
          /**
           * 1----------------------2
           *   \                     \
           *    6                      3
           *   /                     /
           * 5---------------------4
           */

          //        1x  1y,      2x           2y,        3x          3y,          4x               4y,         5x       5y,            6x    6y
          'polygon(6px 2px, calc(100% - 8.5px) 2px, calc(100% - 2px) 50%, calc(100% - 8.5px) calc(100% - 2px), 6px calc(100% - 2px), 12.5px 50%)',

        zIndex: -1,
      },
    },
    disabled: {
      position: 'relative',
      backgroundColor: theme.palette.action.disabledBackground,
    },
    errors: {
      padding: theme.spacing(2),
    },
    errorButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      cursor: 'pointer',
    },
    produced: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    active: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  }
})

export const useProjectStateItem = (stateItem?: ProjectStatus) => {
  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )

  stateItem = stateItem ?? ProjectStatus[currentProjectStatus]

  const req = useAppSelector(
    projectOperationSelector(saveProjectStatus.typePrefix)
  )

  const { classes, cx } = useStyles()

  const stateItemIsInPast = useCallback(() => {
    if (stateItem === ProjectStatus.QUOTED) {
      return currentProjectStatus === ProjectStateAsString.QUOTED
    }

    if (stateItem === ProjectStatus.REQUESTED) {
      return (
        ProjectStatus[ProjectStateAsString.REQUESTED] <=
        ProjectStatus[currentProjectStatus]
      )
    }

    return stateItem < ProjectStatus[currentProjectStatus]
  }, [currentProjectStatus, stateItem])

  const stateItemIsInFuture = useCallback(
    () => stateItem > ProjectStatus[currentProjectStatus],
    [currentProjectStatus, stateItem]
  )

  const stateItemIsNext = useCallback(() => {
    if (stateItem === ProjectStatus.ORDERED) {
      return (
        currentProjectStatus === ProjectStateAsString.QUOTED ||
        currentProjectStatus === ProjectStateAsString.NEGOTIATING
      )
    }
    return stateItem === ProjectStatus[currentProjectStatus] + 1
  }, [currentProjectStatus, stateItem])

  const stateItemIsCurrent = useCallback(() => {
    if (stateItem === ProjectStatus.QUOTED) {
      return currentProjectStatus === ProjectStateAsString.NEGOTIATING
    }

    if (stateItem === ProjectStatus.REQUESTED) {
      if (currentProjectStatus === ProjectStateAsString.REQUESTED) {
        return false
      }
    }

    return stateItem === ProjectStatus[currentProjectStatus]
  }, [currentProjectStatus, stateItem])

  const stateItemIsCurrentOrPast = () =>
    stateItemIsCurrent() || stateItemIsInPast()

  const getBaseClassName = useMemo(() => {
    return cx({
      [classes.disabled]: stateItemIsInFuture(),
      [classes.next]: stateItemIsNext(),
      [classes.active]: stateItemIsCurrent(),
      [classes.produced]: stateItemIsInPast(),
      [classes.errorButton]: stateItem === ProjectStatus.CANCELLED,
      // [classes.order]:
      //   stateItem === ProjectStatus.ORDERED &&
      //   currentProjectStatus === ProjectStateAsString.QUOTED,
    })
  }, [
    classes.active,
    classes.disabled,
    classes.errorButton,
    classes.next,
    classes.produced,
    cx,
    stateItem,
    stateItemIsCurrent,
    stateItemIsInFuture,
    stateItemIsInPast,
    stateItemIsNext,
  ])

  const getStateTranslationKey = () => {
    const prefix = 'project:state-'
    let key = ''

    if (stateItemIsInPast()) {
      key =
        ProjectStatusLabelAfterCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    } else if (stateItemIsInFuture()) {
      key =
        ProjectStatusLabelBeforeCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    } else {
      key =
        ProjectStatusLabelDuringCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    }

    return prefix + key
  }

  const dispatch = useAppDispatch()

  const changeStatus = (to: ProjectStateAsString) => {
    dispatch(
      saveProjectStatus({
        from: currentProjectStatus,
        to: to,
      })
    )
  }

  const saving =
    req?.requestStatus === 'pending' &&
    req?.arg['to'] === ProjectStateAsString[ProjectStatus[stateItem]]

  const SavingComponent = useCallback(() => {
    if (!saving) {
      return null
    }

    return (
      <CircularProgress
        size={12}
        style={{
          position: 'absolute',
          right: '2em',
          top: '3px',
        }}
        color="inherit"
      />
    )
  }, [saving])

  return {
    currentProjectStatus,
    getStateTranslationKey,
    stateItemIsCurrent,
    stateItemIsInPast,
    changeStatus,
    stateItemIsCurrentOrPast,
    stateItemIsInFuture,
    SavingComponent,
    getBaseClassName,
  }
}
