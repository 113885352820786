import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useAppSelector } from 'store/configureStore'
import { ErrorCountBadge } from './ErrorCountBadge'
import { LightTooltip } from './LightTooltip'
import { useProjectStateItem } from './useProjectStateItem'
import { useQuoteProjectState } from './useQuoteProjectState'

const projectState = ProjectStatus.QUOTED
const projectStateAsString = ProjectStateAsString.QUOTED

const visibleProjectStatus = [
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
  ProjectStateAsString.NEGOTIATING,
  ProjectStateAsString.QUOTED,
  ProjectStateAsString.ORDERED,
]
const waitingProjectStatus = [
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
]

export const QuotedState = () => {
  const {
    getStateTranslationKey,
    getBaseClassName,
    changeStatus,
    SavingComponent,
    currentProjectStatus,
  } = useProjectStateItem(projectState)

  const {
    canChangeToNegotiatingState,
    canChangeToQuoteState,
    Errors,
    errorStyle,
    errorCount,
  } = useQuoteProjectState()

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  let stateTranslationKey = ''

  if (currentProjectStatus === ProjectStateAsString.NEGOTIATING) {
    stateTranslationKey = `project:state-${ProjectStateAsString.NEGOTIATING.toLowerCase()}`
  } else if (
    isBuyerView &&
    waitingProjectStatus.includes(currentProjectStatus)
  ) {
    stateTranslationKey = 'project:state-awaiting-quotation'
  } else {
    stateTranslationKey = getStateTranslationKey()
  }

  const handleClick = () => {
    if (canChangeToQuoteState) {
      changeStatus(projectStateAsString)
    }
    if (canChangeToNegotiatingState) {
      changeStatus(ProjectStateAsString.NEGOTIATING)
    }
  }

  if (isBuyerView && !visibleProjectStatus.includes(currentProjectStatus)) {
    return null
  }

  return (
    <LightTooltip title={errorCount > 0 ? <Errors /> : ''}>
      <li
        style={{
          ...errorStyle,
          ...{ pointerEvents: isBuyerView ? 'none' : 'auto' },
        }}
        className={getBaseClassName}
        onClick={handleClick}
      >
        <LocalizedTypography
          translationKey={stateTranslationKey}
          variant="body2"
        />
        <ErrorCountBadge errorCount={errorCount} />

        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
