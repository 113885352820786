import { ProjectStateDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type KanbanEventMap = {
  REFRESH_ALL: null
  FETCH_NEXT_PAGE: {
    status: ProjectStateDto,
    forcePage?: number
  }
  FETCH_NEXT_PAGE_ENDED: {
    projectState: ProjectStateDto
    success: boolean
    error: Error | undefined
    isLastPage: boolean
    lastPageFetched: number
  }
}

export function subscribeToKanbanEvents<T extends keyof KanbanEventMap>(
  eventType: T,
  callback: (eventData: KanbanEventMap[T]) => Promise<void> | void
): { abort: (reason?: string) => void } {
  const abortController = new AbortController()

  window.addEventListener(
    eventType,
    (e) => {
      callback(e['detail'])
    },
    { signal: abortController.signal }
  )

  return abortController
}

export function dispatchKanbanEvents<T extends keyof KanbanEventMap>(
  eventType: T,
  eventData?: KanbanEventMap[T]
) {
  window.dispatchEvent(new CustomEvent(eventType, { detail: eventData }))
}
