import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { saveProjectStatus } from 'features/BillOfMaterials/store/asyncActions/saveProjectStatus'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { ToggleNewItemsModal } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { getDateFormatString } from 'utils/getDateFormatString'
import { useDefiningStateItem } from './useDefiningStateItem'

const errors = []

export const useRequestedProjectState = () => {
  const dispatch = useAppDispatch()
  const projectEmpty = useAppSelector((state: RootAppState) =>
    isEmpty(state.project.assemblyHeadersIds)
  )

  const { closeDialog } = useDialogManager()

  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )

  const projectRequestedDeliveryDate = useAppSelector(
    (state: RootAppState) =>
      moment(state.project.activeProject?.requestedDeliveryDate),
    isEqual
  )

  const projectDeliveryDate = useAppSelector(
    (state) => moment(projectSelectors.projectDeliveryDateSelector(state)),
    isEqual
  )

  const projectIsNesting = useAppSelector(
    projectSelectors.projectIsNestingSelector
  )
  const createdInPortal = useAppSelector(
    projectSelectors.projectCreatedInPortalSelector
  )

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const { canChangeToDefiningState, Errors: DefiningStateErrors } =
    useDefiningStateItem()

  // const projectRequestedDeliveryDateMoment = moment(
  //   projectRequestedDeliveryDate
  // )
  //   .utcOffset(0)
  //   .hour(0)
  //   .minute(0)
  //   .second(0)
  //   .millisecond(0)

  // const estimatedDeliveryMoment =
  //   projectDeliveryDays === null
  //     ? moment(null)
  //     : moment()
  //         .utcOffset(0)
  //         .add(projectDeliveryDays, 'day')
  //         .hour(0)
  //         .minute(0)
  //         .second(0)
  //         .millisecond(0)

  /**
   * you can change to request state if
   * - the project is not empty
   * - you are a salesperson
   * - you can change to defining state
   * - the project is defined in the portal and the requested delivery date is after today or after the estimated delivery date
   */
  const canChangeToRequestState = useMemo(() => {
    if (projectEmpty) {
      return false
    }

    if (!isBuyerView) {
      // a salesperson can always change to request state
      return true
    }

    if (!canChangeToDefiningState) {
      return false
    }

    if (
      isBuyerView &&
      [
        ProjectStateAsString.QUOTING,
        ProjectStateAsString.QUOTED,
        ProjectStateAsString.NEGOTIATING,
        ProjectStateAsString.ORDERED,
      ].includes(currentProjectStatus)
    ) {
      return false
    }

    if (createdInPortal) {
      // a buyer should aways fill the requested delivery date, beeing it after today or after the estimated delivery date
      if (!projectRequestedDeliveryDate.date()) {
        return false
      }

      if (projectRequestedDeliveryDate < projectDeliveryDate) {
        return false
      }
    }

    return true
  }, [
    canChangeToDefiningState,
    createdInPortal,
    currentProjectStatus,
    isBuyerView,
    projectDeliveryDate,
    projectEmpty,
    projectRequestedDeliveryDate,
  ])

  const [errorCount, setErrorCount] = useState(0)

  useEffect(() => {
    errors.length = 0

    if (projectEmpty) {
      errors.push(
        <li
          key="project-empty"
          onClick={() => {
            dispatch(ToggleNewItemsModal(true))
          }}
          style={{ cursor: 'pointer' }}
        >
          <LocalizedTypography
            translationKey="project:empty-project"
            variant="body1"
            color="error"
            component="p"
          />
          <LocalizedTypography
            translationKey="project:empty-project-subtitle"
            variant="caption"
            color="textSecondary"
          />
        </li>
      )
    }

    if (createdInPortal) {
      if (!projectRequestedDeliveryDate.date()) {
        errors.push(
          <li
            key="project-delivery-date"
            onClick={() =>
              document.getElementById('requestedDeliveryDateBtn')?.click()
            }
            style={{ cursor: 'pointer' }}
          >
            <LocalizedTypography
              translationKey="project:requested-delivery-date"
              variant="body1"
              color="error"
              component="p"
            />
            <LocalizedTypography
              translationKey={'project:min-requested-delivery-date'}
              translationDefaultValue="requested delivery date should be today or later"
              variant="caption"
              color="textPrimary"
            />
          </li>
        )
      }

      if (projectRequestedDeliveryDate < projectDeliveryDate) {
        errors.push(
          <li
            key="project-delivery-date"
            onClick={() => {
              closeDialog()
              dispatch(
                saveProjectStatus({
                  from: currentProjectStatus,
                  to: ProjectStateAsString.DEFINING,
                })
              )
              setTimeout(() => {
                document.getElementById('requestedDeliveryDateBtn')?.click()
              })
            }}
            style={{ cursor: 'pointer' }}
          >
            <LocalizedTypography
              translationKey="project:requested-delivery-date"
              variant="body1"
              color="error"
              component="p"
            />
            <LocalizedTypography
              variant="caption"
              component="p"
              translationKey={
                'project:requested-delivery-date-must-be-after-estimated-delivery-date'
              }
              translationDefaultValue="minimum delivery date is {{date}}"
              options={{
                date: moment(projectDeliveryDate).format(getDateFormatString()),
              }}
            />
            {/* <JsonViewer
              data={{
                estimatedDeliveryMoment: estimatedDeliveryMoment,
                projectRequestedDeliveryDateMoment:
                  projectRequestedDeliveryDateMoment,
                projectRequestedDeliveryDate: projectRequestedDeliveryDate,
                rawDeliveryDate: rawDeliveryDate,
              }}
            /> */}
          </li>
        )
      }
    }

    if (projectIsNesting) {
      errors.push(
        <li key={'projectNesting'}>
          <LocalizedTypography
            translationKey="project:project-processing"
            variant="body1"
            component="p"
            color="error"
          >
            processing
          </LocalizedTypography>
          <LocalizedTypography
            translationKey="project:project-processing-subtitle"
            variant="caption"
          >
            wait a few seconds
          </LocalizedTypography>
        </li>
      )
    }

    setErrorCount(errors.length)
  }, [
    createdInPortal,
    dispatch,
    projectRequestedDeliveryDate,
    projectEmpty,
    projectIsNesting,
    closeDialog,
    currentProjectStatus,
    projectDeliveryDate,
  ])

  const Errors = useCallback(() => {
    return (
      <>
        <DefiningStateErrors />
        {!!errors.length && (
          <>
            <ul>{errors}</ul>
          </>
        )}
      </>
    )
  }, [DefiningStateErrors])

  const errorStyle: CSSProperties | undefined = !canChangeToRequestState
    ? {
        cursor: 'not-allowed',
      }
    : undefined

  return {
    canChangeToRequestState,
    Errors,
    errorStyle,
    errorCount,
  }
}
