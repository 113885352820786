// https://github.com/rcdexta/react-trello/issues/340#issuecomment-704796291

import { Button } from '@mui/material'
import React, {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectStateDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { dispatchKanbanEvents, subscribeToKanbanEvents } from '../kanbanEvents'
import { LaneScrollContext } from './LaneScrollContext'

const CARD_HEIGHT = 250
const SCROLL_THRESHOLD = 0.9

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScrollableLane = (props: ReactTrello.LaneProps, _ref) => {
  const { t } = useTranslation()
  const [scrollIndex, setScrollIndex] = useState({
    min: 0,
    max: Math.ceil(window.innerHeight / CARD_HEIGHT) + 2,
  })
  // const [scrollPercentage, setScrollPercentage] = useState(0)

  const scrollerRef = useRef<HTMLDivElement>(null)

  const fetching = useRef(false)
  // const [fetching, setFetching] = useState(false)
  const lastPageFetched = useRef(0)
  const nextPage = useRef(1)
  // const isLastPage = useRef(false)
  const [isLastPage, setLastPage] = useState(false)

  const shouldFetch = useCallback(
    (scrollPercentage: number) => {
      if (fetching.current) {
        return false
      }

      if (isLastPage) {
        return false
      }

      if (lastPageFetched.current - nextPage.current > 1) {
        return false
      }

      if (scrollPercentage < SCROLL_THRESHOLD) {
        return false
      }

      return true
    },
    [isLastPage]
  )

  const handleFetchNextPage = useCallback(
    async (
      scrollPercentage: number,
      projectStatus: ProjectStateDto,
      force?: boolean
    ) => {
      if (scrollPercentage === 0 && nextPage.current > 1) {
        fetching.current = true
        nextPage.current = 1
        dispatchKanbanEvents('FETCH_NEXT_PAGE', {
          status: projectStatus,
          forcePage: 0,
        })
        return
      }

      if (force || shouldFetch(scrollPercentage)) {
        fetching.current = true
        nextPage.current++

        dispatchKanbanEvents('FETCH_NEXT_PAGE', { status: projectStatus })
      }
    },
    [shouldFetch]
  )

  useEffect(() => {
    const columnId = props.children[0]._owner.memoizedProps.id

    const signal = subscribeToKanbanEvents('FETCH_NEXT_PAGE_ENDED', (e) => {
      if (e.projectState.toLowerCase() !== columnId.toLowerCase()) {
        return
      }

      setLastPage(e.isLastPage)

      if (e.success) {
        lastPageFetched.current = e.lastPageFetched
        nextPage.current = e.isLastPage
          ? e.lastPageFetched
          : e.lastPageFetched + 1
      }

      if (e.error) {
        nextPage.current = lastPageFetched.current + 1
        ShowException('Failed to fetch next page', e.error)
      }

      fetching.current = false
    })

    return () => {
      signal.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const signal = subscribeToKanbanEvents('REFRESH_ALL', () => {
      scrollerRef.current.scrollTop = 0
    })

    return () => {
      signal.abort()
    }
  }, [])

  const cardCount = props.children[0]._owner.memoizedProps.label

  const scrollTimer = useRef<NodeJS.Timeout | null>(null)
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current)
      }

      const currentTarget = e.currentTarget
      scrollTimer.current = setTimeout(() => {
        setScrollIndex((prev) => {
          // we're using the async version of hook setting here
          // because react synthetic event objects are reused e.target may be null here
          if (!e.target) {
            return prev
          }
          const scrollableLaneHeightInCards = Math.ceil(
            (scrollerRef.current?.getBoundingClientRect?.()?.height ??
              window.innerHeight) / CARD_HEIGHT
          )
          const nextBaseIndex = Math.floor(e.target['scrollTop'] / CARD_HEIGHT)
          const nextMinIndex = Math.min(
            Math.max(cardCount - scrollableLaneHeightInCards - 2, 0),
            Math.max(nextBaseIndex - 2, 0)
          )
          const nextMaxIndex = nextBaseIndex + scrollableLaneHeightInCards + 2
          if (nextMinIndex === prev.min && nextMaxIndex === prev.max) {
            return prev
          }
          return {
            min: nextMinIndex,
            max: nextMaxIndex,
          }
        })

        const { scrollTop, scrollHeight, clientHeight } = currentTarget
        const scrollPercentage = scrollTop / (scrollHeight - clientHeight)

        if (scrollPercentage === 0 || scrollPercentage > SCROLL_THRESHOLD) {
          handleFetchNextPage(
            scrollPercentage,
            currentTarget.dataset.columnid as ProjectStateDto
          )
        }
      }, 42)
    },
    [cardCount, handleFetchNextPage]
  )

  return (
    <LaneScrollContext.Provider value={scrollIndex}>
      <div
        ref={scrollerRef}
        style={{
          overflow: 'auto',
          height: '100%',
          position: 'relative',
          minWidth: '268px',
        }}
        data-columnid={props.children[0]._owner.memoizedProps.id}
        onDragEnd={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onScroll={handleScroll}
      >
        {Children.map(props.children, (child: React.ReactNode) => {
          if (child) {
            const marginTop = scrollIndex.min * CARD_HEIGHT
            return cloneElement(child as React.ReactElement, {
              // because we are only rendering a subset of our tasks we need to force the scrolling div
              // to have the correct height and use a suitable marginTop so that any tasks from  0 to scrollIndex.min
              // appear to still be there by pushing the rendered cards down to the correct location... hacky :(
              style: {
                // minHeight: `${cardCount * CARD_HEIGHT - marginTop}px`,
                // minHeight: 'fit-content',
                minHeight: isLastPage //&& scrollPercentage > 0.95
                  ? 'max-content'
                  : `${
                      child['props'].children.length * CARD_HEIGHT - marginTop
                    }px`,
                marginTop: `${marginTop}px`,
              },
            })
          }
          return null
        })}
        {!isLastPage ? (
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              fetching.current = false
              handleFetchNextPage(
                1,
                props.children[0]._owner.memoizedProps.id,
                true
              )
            }}
          >
            {t('common:load-more', 'load more items')}
          </Button>
        ) : null}
      </div>
    </LaneScrollContext.Provider>
  )
}

const ForwardedScrollableLane = React.forwardRef(ScrollableLane)

export default ForwardedScrollableLane
