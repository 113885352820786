import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { bomItemChildrenSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { projectOperationSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import _, { cloneDeep } from 'lodash'
import LogRocket from 'logrocket'
import { MultiplyMoney, SumMoney } from 'model/Money'
import { MultiplyQuantity } from 'model/MultiplyQuantity'
import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { SumQuantity } from 'model/SumQuantity'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ManuallySetProductionTimeArgs,
  MoneyDto,
  PriceRowDto,
  PriceRowsDto,
  PriceScope,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { store, useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { PriceDetailsGraphData, getSumOfChildrens } from './useBomItemDetails'

const multiplyUnitPriceByTotalProjectQuantity = (
  priceDetails: PriceRowDto,
  bomItemTotalProjectQuantity: number
): PriceRowDto => {
  if (!priceDetails) return null

  const multiplier = (priceDetails: PriceRowDto) => {
    const modifiedPriceDetails = {
      ...priceDetails,
      productionTime: MultiplyQuantity(
        priceDetails.productionTime,
        bomItemTotalProjectQuantity
      ),
      productionTimeCosts: MultiplyMoney(
        priceDetails.productionTimeCosts,
        bomItemTotalProjectQuantity
      ),
      productionTimeManuallySet: MultiplyQuantity(
        priceDetails.productionTimeManuallySet,
        bomItemTotalProjectQuantity
      ),
      additionalProductionCosts: MultiplyMoney(
        priceDetails.additionalProductionCosts,
        bomItemTotalProjectQuantity
      ),
      additionalProductionCostsManuallySet: MultiplyMoney(
        priceDetails.additionalProductionCostsManuallySet,
        bomItemTotalProjectQuantity
      ),
      costPrice: MultiplyMoney(
        priceDetails?.costPrice,
        bomItemTotalProjectQuantity
      ),
      surchargeValue: MultiplyMoney(
        priceDetails.surchargeValue,
        bomItemTotalProjectQuantity
      ),
      discountValue: MultiplyMoney(
        priceDetails.discountValue,
        bomItemTotalProjectQuantity
      ),
      salesPrice: MultiplyMoney(
        priceDetails.salesPrice,
        bomItemTotalProjectQuantity
      ),
    }

    return modifiedPriceDetails
  }

  const modifiedPriceDetails = multiplier(priceDetails)
  modifiedPriceDetails.children = priceDetails.children.map(multiplier)

  return modifiedPriceDetails
}

const calculatePriceDetailsForPriceScope = (
  priceDetails: PriceRowDto[],
  scope: PriceScope,
  totalProjectQuantity: number
): PriceRowDto[] => {
  if (!priceDetails) {
    return []
  }

  const modifiedPriceDetails = priceDetails.map((x) => {
    let modified = { ...x }

    if (scope === PriceScope.Total) {
      modified = multiplyUnitPriceByTotalProjectQuantity(
        x,
        totalProjectQuantity
      )
    }

    // TODO: check if this is needed ->
    // batch independent items should show the total value instead of the value per unita
    // if (scope === PriceScope.Unit) {
    //   modified.children = x.children.map((child) => {
    //     if (child.isBatchIndependent) {
    //       return multiplyUnitPriceByTotalProjectQuantity(
    //         child,
    //         totalProjectQuantity
    //       )
    //     }

    //     return child
    //   })
    // }

    return modified
  })

  return modifiedPriceDetails
}

export function useFinancialDetails(props: {
  open?: boolean
  bomItemPointer: BomItemPointer
  filterZeroEstimatedProductionTime?: boolean
}) {
  const { projectId } = useOrganizationAndProjectContext()
  const dispatch = useAppDispatch()

  const projectFetchOperation = useAppSelector(
    projectOperationSelector('project/fetchProject')
  )

  const { controller, loading, setLoading } = useAppController(
    () => new BomItemController()
  )

  const [
    filteringZeroEstimatedProductionTime,
    setFilteringZeroEstimatedProductionTime,
  ] = useState(false)

  const [summarizedPriceDetails, setSummarizedPriceDetails] =
    useState<PriceDetailsGraphData[]>(undefined)

  const [priceDetails, setPriceDetails] = useState<PriceRowDto[]>(undefined)
  const [originalPriceDetails, setOriginalPriceDetails] =
    useState<PriceRowDto[]>(undefined)

  const bomItemTotalProjectQuantity = useAppSelector(
    projectSelectors.bomItemFinancialSelector(
      props.bomItemPointer,
      'totalProjectQuantity'
    )
  ) as number

  const bomItemTargetPricePerUnit = useAppSelector(
    projectSelectors.bomItemFinancialSelector(
      props.bomItemPointer,
      'targetSalesPricePerItem'
    )
  ) as MoneyDto

  const getChildItems = useCallback(
    (
      bomItemPointer: BomItemPointer,
      scope: PriceScope
    ): PriceDetailsGraphData => {
      if (!store.getState().project.activeProject) {
        return null
      }

      const childrenBomItems = bomItemChildrenSelector(bomItemPointer)(
        store.getState()
      ) as PartTypeRow[]

      if (!childrenBomItems?.length) return null

      const children = childrenBomItems
        .sort(
          (a, b) =>
            b.financial?.salesPricePerItem?.value -
            a.financial?.salesPricePerItem?.value
        )
        .reduce(
          (p, c, i) => ({
            ...p,
            [i]: {
              bomItemPointer: {
                id: c.id,
                type: c.type,
              },
              description: c.name,
              primary:
                scope === PriceScope.Unit
                  ? c.financial?.costPricePerItem
                  : c.financial?.costPriceOfItems,
              salesPrice:
                scope === PriceScope.Unit
                  ? c.financial?.salesPricePerItem
                  : c.financial?.salesPriceOfItems,
              color: 'transparent',
              isMaterial: false,
              isWorkingStep: false,
              workingStep: undefined,
            } as PriceDetailsGraphData,
          }),
          {}
        )

      return {
        bomItemPointer: undefined,
        description:
          props.bomItemPointer.type === BomItemType.project
            ? 'assemblies'
            : 'parts',
        childrenBomItems: children,
        total: getSumOfChildrens(bomItemPointer, scope),
        salesPrice: undefined,
        sharedCost: false,
        matchDescription: undefined,
        isMaterial: false,
        isWorkingStep: false,
        workingStep: undefined,
      }
    },
    [props.bomItemPointer.type]
  )

  const getPriceDataForGraph = useCallback(
    (
      details: PriceRowDto[],
      bomItemPointer: BomItemPointer,
      priceScope: PriceScope
    ): PriceDetailsGraphData[] => {
      const priceRows = details
        .filter((x) => !x.isTotalRow)
        .flatMap((x) => x.children)

      const data: Array<PriceDetailsGraphData> = []
      data.push(getChildItems(bomItemPointer, priceScope))

      priceRows.forEach((priceRow) => {
        const productionTimeTotal = SumQuantity([
          priceRow.productionTime,
          ...priceRow.children.map((x) => x.productionTime),
        ])
        const salesPriceTotal = SumMoney([
          priceRow.salesPrice,
          ...priceRow.children.map((x) => x.salesPrice),
        ])
        const surchargetTotal = SumMoney([
          priceRow.surchargeValue,
          ...priceRow.children.map((x) => x.surchargeValue),
        ])
        const discountTotal = SumMoney([
          priceRow.discountValue,
          ...priceRow.children.map((x) => x.discountValue),
        ])

        data.push({
          bomItemPointer: bomItemPointer,
          description: priceRow.workingStep.primaryWorkingStep,
          resource: priceRow.workingStep?.resource?.name,
          productionTime: productionTimeTotal,
          total: salesPriceTotal,
          primaryWorkingStep: priceRow.workingStep.primaryWorkingStep,
          salesPrice: salesPriceTotal,
          mainActivityCostPrice: priceRow.costPrice,
          setup: priceRow.children.find(
            (x) => x.workingStep?.secondaryWorkingStep === WorkingStepType.Setup
          )?.costPrice,
          loading: priceRow.children.find(
            (x) =>
              x.workingStep?.secondaryWorkingStep === WorkingStepType.Loading
          )?.costPrice,
          unloading: priceRow.children.find(
            (x) =>
              x.workingStep?.secondaryWorkingStep === WorkingStepType.Unloading
          )?.costPrice,
          materialSalesPrice: priceRow.children.find((x) => !x.isWorkingStep)
            ?.costPrice,
          materialDescription:
            priceRow.children.find((x) => !x.isWorkingStep)?.description ||
            'not set',
          materialId: priceRow.children.find((x) => !x.isWorkingStep)
            ?.materialId,
          discountValue: MultiplyMoney(discountTotal, -1),
          surchargeValue: surchargetTotal,
          discountRatio: priceRow.discountRatio,
          surchargeRatio: priceRow.surchargeRatio,
          isMaterial: false,
          isWorkingStep: priceRow.isWorkingStep,
          workingStep: priceRow.workingStep,
        })
      })

      const totalRow = details.find((x) => x.isTotalRow)
      const totalMaterialsSalesPrices = SumMoney(
        priceRows
          .map((x) => x.children)
          .flat()
          .filter((x) => !x.isWorkingStep)
          .map((x) => x.costPrice)
      )

      data.push({
        bomItemPointer: undefined,
        description: 'total',
        mainActivityCostPrice: SumMoney([
          totalRow?.costPrice,
          MultiplyMoney(totalMaterialsSalesPrices, -1),
        ]),
        surchargeValue: totalRow?.surchargeValue,
        discountValue: {
          ...(totalRow?.discountValue || {}),
          value: (totalRow?.discountValue?.value || 0) * -1,
        },
        salesPrice: totalRow?.salesPrice,
        sharedCost: totalRow?.costsAreShared,
        matchDescription: undefined,
        materialSalesPrice: totalMaterialsSalesPrices,
        discountRatio: totalRow?.discountRatio,
        surchargeRatio: totalRow?.surchargeRatio,
        isMaterial: false,
        isWorkingStep: false,
        workingStep: undefined,
      })

      return data.filter((x) => Boolean(x))
    },
    [getChildItems]
  )

  const [priceScope, setPriceScope] = useClientStorage<PriceScope>(
    'project:financial-scope',
    PriceScope.Total
  )

  const handleChangeFinancialView = useCallback(
    (priceDetailsPerUnit: PriceRowDto[], scope: PriceScope) => {
      if (!bomItemTotalProjectQuantity) {
        return null
      }

      setPriceScope(scope)

      const calculatedPriceDetails = calculatePriceDetailsForPriceScope(
        priceDetailsPerUnit,
        scope,
        bomItemTotalProjectQuantity
      )

      setPriceDetails(calculatedPriceDetails)

      setSummarizedPriceDetails(
        getPriceDataForGraph(
          calculatedPriceDetails,
          props.bomItemPointer,
          scope
        )
      )
    },
    [
      bomItemTotalProjectQuantity,
      getPriceDataForGraph,
      props.bomItemPointer,
      setPriceScope,
    ]
  )

  if (priceScope.toString() === 'perUnit') {
    // backward compatibility
    handleChangeFinancialView(originalPriceDetails, PriceScope.Unit)
  }

  const filterZeroEstimatedProductionTime = useCallback(
    (priceDetails: PriceRowsDto) => {
      if (!priceDetails) {
        return null
      }

      if (!props.filterZeroEstimatedProductionTime) {
        setFilteringZeroEstimatedProductionTime(false)
        return priceDetails
      }

      const newPriceDetails = cloneDeep(priceDetails)

      if (props.filterZeroEstimatedProductionTime) {
        for (let i = 0; i < newPriceDetails.priceRows.length; i++) {
          const price = { ...newPriceDetails.priceRows[i] }
          if (price.isTotalRow) {
            continue
          }

          newPriceDetails.priceRows[i].children = [
            ...price.children.filter((child) => {
              return (
                child.isWorkingStep &&
                child.productionTime.value === 0 &&
                !child.productionTimeManuallySet?.value
              )
            }),
          ]
        }

        newPriceDetails.priceRows = newPriceDetails.priceRows.filter(
          (price) => price.children.length > 0
        )
      }

      if (newPriceDetails.priceRows.length === 0) {
        setFilteringZeroEstimatedProductionTime(false)
        return priceDetails
      } else {
        setFilteringZeroEstimatedProductionTime(true)
        return newPriceDetails
      }
    },
    [props.filterZeroEstimatedProductionTime]
  )

  const _getItemPriceDetails = useCallback(
    async (bomItemPointer: BomItemPointer) => {
      try {
        let priceDetails = await controller.GetItemPriceDetails(bomItemPointer)

        priceDetails = filterZeroEstimatedProductionTime(priceDetails)

        if (!priceDetails) {
          setPriceDetails([])
          return
        }

        const calculatedPriceDetails = calculatePriceDetailsForPriceScope(
          priceDetails.priceRows,
          priceScope,
          bomItemTotalProjectQuantity
        )

        setOriginalPriceDetails(priceDetails.priceRows)
        setPriceDetails(calculatedPriceDetails)

        setSummarizedPriceDetails(
          getPriceDataForGraph(
            calculatedPriceDetails,
            bomItemPointer,
            priceScope
          )
        )
      } catch (err) {
        LogRocket.captureException(err as Error)
      }
    },
    [
      bomItemTotalProjectQuantity,
      controller,
      filterZeroEstimatedProductionTime,
      getPriceDataForGraph,
      priceScope,
    ]
  )

  const getItemPriceDetails = useMemo(
    () =>
      _.throttle(_getItemPriceDetails, 5000, {
        trailing: true,
      }),
    [_getItemPriceDetails]
  )

  useEffect(() => {
    _getItemPriceDetails(props.bomItemPointer)
  }, [_getItemPriceDetails, props.bomItemPointer])

  const handleResetProductionTime = useCallback(
    async (bomItemPointer: BomItemPointer, priceRow: PriceRowDto) => {
      try {
        setPriceDetails((current) => {
          const newPriceRows = current.map((x) => {
            if (x.workingStep?.key === priceRow.workingStep?.key) {
              return {
                ...x,
                productionTimeManuallySet: undefined,
              }
            }

            x.children = x.children.map((y) => {
              if (y.workingStep?.key === priceRow.workingStep?.key) {
                return {
                  ...y,
                  productionTimeManuallySet: undefined,
                }
              }

              return y
            })

            return x
          })

          return newPriceRows
        })

        await controller.DeleteManualSetProductionTime(bomItemPointer, priceRow)
      } catch (ex) {
        ShowException('project', ex)
        dispatch(() => fetchProject({ projectId }))
      }
    },
    [controller, dispatch, projectId]
  )

  const handleResetAdditionalCosts = useCallback(
    async (bomItemPointer: BomItemPointer, priceRow: PriceRowDto) => {
      try {
        setPriceDetails((current) => {
          const newPriceRows = current.map((x) => {
            if (x.workingStep?.key === priceRow.workingStep?.key) {
              return {
                ...x,
                additionalProductionCostsManuallySet: undefined,
              }
            }

            x.children = x.children.map((y) => {
              if (y.workingStep?.key === priceRow.workingStep?.key) {
                return {
                  ...y,
                  additionalProductionCostsManuallySet: undefined,
                }
              }

              return y
            })

            return x
          })

          return newPriceRows
        })

        await controller.DeleteManualSetAdditionalCosts(
          bomItemPointer,
          priceRow
        )
      } catch (ex) {
        ShowException('reset additional costs', ex)
        dispatch(() => fetchProject({ projectId }))
      }
    },
    [controller, dispatch, projectId]
  )

  const handleSaveProductionTime = useCallback(
    async (
      bomItemPointer: BomItemPointer,
      args: ManuallySetProductionTimeArgs
    ) => {
      try {
        const newCurrent = [...priceDetails]
        let shallSave = false

        newCurrent.forEach((parent) => {
          parent.children = parent.children.map((child) => {
            if (
              child.workingStep?.primaryWorkingStep ===
                args.primaryWorkingStep &&
              child.workingStep?.secondaryWorkingStep ===
                args.secondaryWorkingStep
            ) {
              if (
                child.productionTimeManuallySet?.value !==
                args.productionTime.value
              ) {
                shallSave = true
              }

              return {
                ...child,
                productionTimeManuallySet: args.productionTime,
                salesPrice: undefined,
              }
            }

            return child
          })
        })

        setPriceDetails(newCurrent)

        if (shallSave) {
          await controller.ManualSetProductionTime(bomItemPointer, args)
        }
        return Promise.resolve()
      } catch (ex) {
        ShowException('save production time', ex)
        _getItemPriceDetails(props.bomItemPointer)
      }
    },
    [_getItemPriceDetails, controller, priceDetails, props.bomItemPointer]
  )

  const handleSaveAdditionalCosts = useCallback(
    async (
      bomItemPointer: BomItemPointer,
      priceRow: PriceRowDto,
      priceScope: PriceScope
    ) => {
      try {
        const newCurrent = [...priceDetails]
        let shallSave = false

        newCurrent.map((x) => {
          if (x.workingStep?.key === priceRow.workingStep?.key) {
            if (
              x.additionalProductionCostsManuallySet?.value !==
              priceRow.additionalProductionCostsManuallySet?.value
            ) {
              shallSave = true
            }
            return {
              ...x,
              additionalProductionCostsManuallySet:
                priceRow.additionalProductionCostsManuallySet,
              salesPrice: undefined,
            }
          } else {
            return {
              ...x,
              children: x.children.map((y) => {
                if (y.workingStep?.key === priceRow.workingStep?.key) {
                  if (
                    y.additionalProductionCostsManuallySet?.value !==
                    priceRow.additionalProductionCostsManuallySet?.value
                  ) {
                    shallSave = true
                  }

                  return {
                    ...y,
                    additionalProductionCostsManuallySet:
                      priceRow.additionalProductionCostsManuallySet,
                    salesPrice: undefined,
                  }
                }
                return y
              }),
            }
          }
        })

        setPriceDetails(newCurrent)

        if (shallSave) {
          await controller.ManualSetAdditionalCosts(bomItemPointer, {
            additionalCosts: priceRow.additionalProductionCostsManuallySet,
            priceScope: priceScope,
            primaryWorkingStep: priceRow.workingStep.primaryWorkingStep,
            secondaryWorkingStep: priceRow.workingStep.secondaryWorkingStep,
          })

          // await _getItemPriceDetails(props.bomItemPointer)
        }
      } catch (ex) {
        ShowException('save additional costs', ex)
        _getItemPriceDetails(props.bomItemPointer)
      }
    },
    [_getItemPriceDetails, controller, priceDetails, props.bomItemPointer]
  )

  useEffect(() => {
    return () => {
      controller.CancelRequests
      setPriceDetails([])
      setOriginalPriceDetails([])
      setSummarizedPriceDetails([])
      getItemPriceDetails.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bomItemPointer?.id])

  useEffect(() => {
    if (
      props.open &&
      (!projectFetchOperation ||
        projectFetchOperation?.requestStatus === 'fulfilled')
    ) {
      getItemPriceDetails(props.bomItemPointer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    controller.CancelRequests,
    projectFetchOperation,
    projectFetchOperation?.requestStatus,
    props.bomItemPointer,
    props.open,
  ])

  return {
    getPriceDataForGraph,
    summarizedPriceDetails,
    priceDetails,
    loading,
    controller,
    getItemPriceDetails,
    setLoading,
    priceScope,
    handleChangeFinancialView,
    bomItemTotalProjectQuantity,
    bomItemTargetPricePerUnit,
    handleResetProductionTime,
    handleResetAdditionalCosts,
    handleSaveProductionTime,
    handleSaveAdditionalCosts,
    clearPriceDetails: () => setPriceDetails(undefined),
    originalPriceDetails,
    filteringZeroEstimatedProductionTime,
  }
}
