import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { CSSProperties, useCallback } from 'react'
import { useAppSelector } from 'store/configureStore'
import { useRequestedProjectState } from './useRequestedProjectState'

const errors = []

export const useQuotingProjectState = () => {
  const {
    canChangeToRequestState,
    // Errors: RequestedErrors,
    // errorCount: requestedErrorsCount,
  } = useRequestedProjectState()

  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  // const [errorCount, setErrorCount] = useState(requestedErrorsCount)

  /**
   *
   */
  const canChangeToQuotingState = canChangeToRequestState && !isBuyerView

  // useEffect(() => {
  //   setErrorCount(requestedErrorsCount)
  // }, [canChangeToQuotingState, requestedErrorsCount])

  const Errors = useCallback(() => {
    return (
      <>
        {/* <RequestedErrors /> */}
        {!!errors.length && <ul>{errors}</ul>}
      </>
    )
    // }, [RequestedErrors])
  }, [])

  const errorStyle: CSSProperties | undefined = !canChangeToQuotingState
    ? {
        cursor: 'not-allowed',
      }
    : undefined

  return {
    canChangeToQuotingState,
    Errors,
    errorStyle,
    // errorCount,
    currentProjectStatus,
  }
}
