import {
  Checkbox,
  FormControlLabel,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Board from 'react-trello'
import {
  ProjectStateDto,
  ProjectSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { makeStyles } from 'tss-react/mui'
import SearchTextField, {
  SearchTextFieldRef,
} from '../../Common/SearchTextBox/SearchTextField'
import KanbanProjectSummaryCard from './KanbanProjectSummaryCard'

import { RefreshButton } from 'components/Common/Button/RefreshWithProgress'
import { useClientStorage } from 'customHooks/useClientStorage'
import React from 'react'
import ScrollableLane from './KanbanComponents/ScrollableLane'
import { dispatchKanbanEvents } from './kanbanEvents'
import './KanbanView.sass'
import { useProjectsKanban } from './useProjectKanban'

const useStyles = makeStyles()((theme: Theme) => ({
  board: {
    width: '100%',
    margin: '0 auto',
    color: theme.palette.text.primary,
    '& .react-trello-lane': {
      // height: 'calc(100vh - 240px)',
      height: '100%',
      maxHeight: '100%',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRight: `3px dashed ${theme.palette.divider}`,
      justifyContent: 'space-between',
      // borderRadius: theme.shape.borderRadius,
    },
  },
  toolbar: {
    padding: theme.spacing(2, 8),
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  search: {
    [theme.breakpoints.up('lg')]: {
      width: '24vw',
    },
  },
}))

export const ProjectsKanban = (props: {
  isFirstRender: boolean
  currentQuery: string
  onQueryChange: (query: string) => void
}) => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  const {
    onlyMyProjects,
    setOnlyMyProjects,
    updateProjectStatus,
    handleSetPriority,
    setShowCanceledProjects,
    showCanceledProjects,
    getProjectListAndLanes,
    lanes,
    setLanes,
    boardRef,
    kanbanProjectStates,
    eventBus,
    setEventBus,
    resetPagination,
  } = useProjectsKanban()

  const updateKanbanBoard = useCallback(
    async (
      projectStates: undefined | Partial<ProjectStateDto>[],
      onlyMyProjects: boolean,
      showCanceledProjects: boolean
    ) => {
      try {
        const { lanes: updatedLanes } = await getProjectListAndLanes(
          projectStates ?? kanbanProjectStates,
          onlyMyProjects,
          showCanceledProjects
        )

        const allLanes = lanes.lanes.map((lane) => {
          const updatedLane = updatedLanes.lanes.find((x) => x.id === lane.id)
          if (updatedLane) {
            return updatedLane
          }
          return lane
        })

        // eventBus.current.publish({
        //   type: 'UPDATE_LANES',
        //   lanes: allLanes,
        // })

        setLanes({ lanes: allLanes })

        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: { lanes: allLanes },
        })
      } catch (err) {
        ShowException('unable to refresh', err)
      }
    },
    [
      getProjectListAndLanes,
      kanbanProjectStates,
      lanes?.lanes,
      setLanes,
      eventBus,
    ]
  )

  const [currentQuery, setCurrentQuery] = useClientStorage('currentQuery', '')

  const { t } = useTranslation()
  const searchInputRef = React.createRef<SearchTextFieldRef>()

  const handleDragEnd = async (
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    cardDetails: { id: string; laneId: string; metadata: ProjectSummaryDto }
  ) => {
    let sourceLane = sourceLaneId ?? cardDetails?.metadata.status
    sourceLane = sourceLane[0].toUpperCase() + sourceLane.slice(1)

    try {
      if (!sourceLane) {
        console.info('[handleDragEnd]: no source lane id or card lane id')
        return false
      }

      eventBus.current?.publish({
        type: 'MOVE_CARD',
        fromLaneId: sourceLane,
        toLaneId: targetLaneId,
        cardId: cardId,
        index: 0,
      })

      await updateProjectStatus(
        cardDetails.id,
        sourceLane,
        targetLaneId,
        0 // position
      )

      // returning false will make the react-trello library do not update the
      // board state (we did it manually)
      return false
    } catch (err) {
      ShowException('update project', err)
      eventBus.current?.publish({
        type: 'MOVE_CARD',
        fromLaneId: targetLaneId,
        toLaneId: sourceLane,
        cardId: cardId,
        index: position,
      })

      return false
    }
  }

  const onSetPriority = useCallback(
    async (laneId: ProjectStateDto, projectId: string, priority: number) => {
      try {
        await handleSetPriority(laneId, projectId, priority)

        // await updateKanbanBoard([laneId], onlyMyProjects, showCanceledProjects)
      } catch (err) {
        ShowException('set priority', err)
        updateKanbanBoard(undefined, onlyMyProjects, showCanceledProjects)
      }
    },
    [handleSetPriority, onlyMyProjects, showCanceledProjects, updateKanbanBoard]
  )

  const handleCardClick = useCallback(
    (cardId: string) => {
      navigate(`/app/project/${cardId}`)
    },
    [navigate]
  )

  const handleSearch = useCallback(
    async (search: string) => {
      setCurrentQuery(search)

      resetPagination()

      await updateKanbanBoard(undefined, onlyMyProjects, showCanceledProjects)

      props.onQueryChange(search)
    },
    [
      onlyMyProjects,
      props,
      resetPagination,
      setCurrentQuery,
      showCanceledProjects,
      updateKanbanBoard,
    ]
  )

  const handleReload = useCallback(() => {
    // dispatchKanbanEvents('REFRESH_ALL')

    resetPagination()

    setTimeout(async () => {
      await updateKanbanBoard(
        kanbanProjectStates,
        onlyMyProjects,
        showCanceledProjects
      )
    }, 0)
  }, [
    resetPagination,
    updateKanbanBoard,
    kanbanProjectStates,
    onlyMyProjects,
    showCanceledProjects,
  ])

  const handleOnlyMyProjects = useCallback(
    async (_, filterMyProjects: boolean) => {
      setOnlyMyProjects(filterMyProjects)
      await updateKanbanBoard(undefined, filterMyProjects, showCanceledProjects)
    },
    [setOnlyMyProjects, showCanceledProjects, updateKanbanBoard]
  )

  const KanbanCard = useCallback(
    (props) => {
      return (
        <KanbanProjectSummaryCard
          {...props}
          // loading={loading}
          setPriority={onSetPriority}
        />
      )
    },
    [onSetPriority]
  )

  const handleIncludeCanceledProjects = useCallback(
    async (e, checked) => {
      setShowCanceledProjects(checked)
      if (checked) {
        await updateKanbanBoard(
          [ProjectStateDto.Cancelled],
          onlyMyProjects,
          checked
        )

        dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
          error: undefined,
          isLastPage: false,
          lastPageFetched: 0,
          projectState: ProjectStateDto.Cancelled,
          success: true,
        })
      }
    },
    [onlyMyProjects, setShowCanceledProjects, updateKanbanBoard]
  )

  const paginateLane = useCallback(
    async (requestedPage: number, laneId: string) => {
      try {
        const { lanes } = await getProjectListAndLanes(
          [laneId as never],
          onlyMyProjects,
          showCanceledProjects,
          requestedPage
        )

        return lanes.lanes
          .filter((x) => x.id === laneId)
          .flatMap((x) => x.cards)
      } catch (err) {
        ShowException('pagination', err)
        return null
      }
    },
    [getProjectListAndLanes, onlyMyProjects, showCanceledProjects]
  )

  // useEffect(() => {
  //   subscribeToKanbanEvents('FETCH_NEXT_PAGE_ENDED', e => {

  //   })
  // }, [])

  return (
    <Paper className={classes.board}>
      <div className={classes.toolbar}>
        <SearchTextField
          searchOnEnterOrBlur
          onSearch={handleSearch}
          defaultValue={currentQuery}
          inputProps={{
            id: 'search-projects',
          }}
          className={classes.search}
          ref={searchInputRef}
          variant="outlined"
          size="small"
          style={{
            minWidth: '250px',
          }}
          helperText={
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '2em',
              }}
            >
              <Tooltip
                title={t(
                  'common:my-projects-filter-help',
                  'shows only projects created by or where the sales representative is assigned to my user'
                )}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      id={'only-my-projects'}
                      checked={onlyMyProjects}
                      onChange={handleOnlyMyProjects}
                      style={{
                        padding: 0,
                        marginRight: '1em',
                      }}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('common:my-projects', 'my projects')}
                    </Typography>
                  }
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    id={'show-canceled-projects'}
                    checked={showCanceledProjects}
                    onChange={handleIncludeCanceledProjects}
                    style={{
                      padding: 0,
                      marginRight: '1em',
                    }}
                  />
                }
                label={
                  <Typography variant="caption">
                    {t('common:show-canceled-projects-label', 'my projects')}
                  </Typography>
                }
              />
            </span>
          }
        />
        <RefreshButton
          // loading={loading['GetProjectsListPaged']}
          loading={false}
          onClick={handleReload}
          size="small"
        />
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <LoadingContainer loading={!lanes} noMessage>
          <div style={{ width: '100%' }}>
            {/* <Board data={data} draggable /> */}
            <Board
              ref={boardRef}
              data={lanes}
              components={{
                Card: KanbanCard,
                ScrollableLane: ScrollableLane,
              }}
              onLaneScroll={paginateLane}
              hideCardDeleteIcon
              handleDragEnd={handleDragEnd}
              onCardClick={handleCardClick}
              eventBusHandle={setEventBus}
            />
          </div>
        </LoadingContainer>
      </div>
    </Paper>
  )
}
