import { useAuth0 } from '@auth0/auth0-react'
// import { Typography } from '@mui/material'
import { useAppLogin } from 'features/Auth/useAppLogin'
import { Navigate } from 'react-router-dom'
import Spinning from './Spinner/Spinning'

const HandleAuthentication = () => {
  const auth0 = useAuth0()
  const { returnUrl, clearLoginTries } = useAppLogin()

  if (auth0.isLoading) {
    return <Spinning fixed />
  }

  if (!auth0.isAuthenticated) {
    // console.log('location state', location.state)
    clearLoginTries()

    return <Spinning fixed />
  }

  // return <Typography>handle authentication</Typography>
  return <Navigate to={returnUrl || '/app'} />
}

export default HandleAuthentication
