import { DebounceSettings, DebouncedFunc, debounce } from 'lodash'

/**
 * debounceByArgs wraps a function so that calls for the *same* arguments
 * are debounced together, while calls for different arguments use their
 * own debounced timers.
 *
 * @param fn       The function to debounce
 * @param wait     The number of milliseconds to delay
 * @param options  Lodash debounce options (leading, trailing, etc.)
 * @returns        A wrapper function that debounces `fn` per unique arguments
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounceByArgs<T extends (...args: any[]) => any>(
  fn: T,
  wait: number,
  options?: DebounceSettings
): (...args: Parameters<T>) => ReturnType<T> | undefined {
  // cache maps a stringified version of arguments to a debounced function
  const cache: Record<string, DebouncedFunc<T>> = {}

  return function debouncedWrapper(
    ...args: Parameters<T>
  ): ReturnType<T> | undefined {
    const key = JSON.stringify(args)

    // If there's no debounced function cached for these arguments, create one
    if (!cache[key]) {
      cache[key] = debounce(
        async (...debouncedArgs: Parameters<T>) => {
          return await fn(...debouncedArgs)
        },
        wait,
        options
      )
    }

    // Call the debounced function associated with this set of arguments
    return cache[key](...args)
  }
}
