import {
  Button,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  NestingDirection,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { saveNestingDirection } from '../store/asyncActions/saveNestingDirection'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemActivitiesSelectors } from '../store/selectors/activities/bomItemActivitiesSelectors'
import { bomItemNestingTypeSelector } from '../store/selectors/bomItemNestingTypeSelector'
import { projectOperationIsPendingSelector } from '../store/selectors/projectOperationPendingSelector'
import { NestingIcon } from './NestingIcon'

type Props = {
  bomItemPointer: BomItemPointer
  workingStepType?: WorkingStepType
}

export const PartTypeNestingDirection = ({
  bomItemPointer,
}: Props) => {
  const dispatch = useAppDispatch()
  const nesting = useAppSelector(
    bomItemNestingTypeSelector(bomItemPointer),
    isEqual
  )

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const operationPending = useAppSelector(
    projectOperationIsPendingSelector(
      saveNestingDirection.typePrefix.concat('/', bomItemPointer.id)
    )
  )

  const rawMaterialActivity: BoMItemActivityDto = useAppSelector(
    bomItemActivitiesSelectors.rawMaterialActivity(bomItemPointer),
    isEqual
  )

  const btnRef = useRef(undefined)

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)

  if (bomItemPointer.type === BomItemType.partInstance) {
    return null
  }

  if (rawMaterialActivity.primaryWorkingStep !== WorkingStepType.SheetCutting) {
    return null
  }

  if (
    !nesting ||
    nesting.selectedDirection === NestingDirection.NotInitialized
  ) {
    return null
  }

  const handleNestingDirectionSelected = (direction: NestingDirection) => {
    setOpen(false)
    dispatch(
      saveNestingDirection({
        bomItemPointer: bomItemPointer,
        nestingDirection: direction,
      })
    )
  }

  return (
    <>
      <Tooltip
        title={t('project:nesting-direction', 'nesting direction')}
        arrow
      >
        <span>
          <Button
            size="small"
            variant="text"
            disabled={!projectIsEditable}
            ref={btnRef}
            startIcon={
              operationPending ? (
                <CircularProgress size={16} />
              ) : (
                <NestingIcon nestingDirection={nesting.selectedDirection} />
              )
            }
            onClick={() => setOpen(true)}
          >
            <Typography variant="caption" color="textSecondary">
              {t(
                `common:nesting-rotation-${
                  NestingDirection[nesting.selectedDirection]
                }`
              ).toLowerCase()}
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={btnRef.current}
      >
        {nesting.selectableDirections.map((direction) => {
          return (
            <MenuItem
              key={direction}
              onClick={() => handleNestingDirectionSelected(direction)}
            >
              <ListItemAvatar>
                <NestingIcon nestingDirection={direction} />
              </ListItemAvatar>
              <ListItemText
                primary={t(
                  `common:nesting-rotation-${NestingDirection[direction]}`
                ).toLowerCase()}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
