import { ProjectsSummariesController } from 'controllers/Projects/ProjectsSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { AppEventsController } from 'features/AppEventTrack/appEventsController'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { noop } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProjectStateDto,
  ProjectSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { debounceByArgs } from 'utils/debounceByArgs'
import { useOrganizationContext } from 'utils/useOrganizationContext'
import { useShowException } from 'utils/useShowException'
import { dispatchKanbanEvents, subscribeToKanbanEvents } from './kanbanEvents'

type ProjectCard = ReactTrello.DraggableCard<
  ProjectSummaryDto & {
    /** the page used to get this card */
    page: number
  }
>

export type ProjectListPaginationEndedEvent = {
  projectStatus: ProjectStateDto
  success: boolean
  error: Error
  lastPageFetched: number
  isLastPage: boolean
}

export const KANBAN_COLUMN_PAGE_SIZE = 20

export const kanbanProjectStates = Object.values(ProjectStateDto).filter(
  (x) => x !== ProjectStateDto.NotInitialized
)

const getGroupName = (partyId: string) => `${partyId}_project_list`

export const useProjectsKanban = () => {
  const [showCanceledProjects, setShowCanceledProjects] =
    useClientStorage<boolean>('showCanceledProjects', false)

  const { t } = useTranslation()

  const [onlyMyProjects, setOnlyMyProjects] = useClientStorage<boolean>(
    'filterMyProjects',
    false
  )

  const boardRef = useRef<ReactTrelloBoard<ProjectSummaryDto>>(undefined)

  const [lanes, setLanes] = useState<ReactTrello.BoardData<ProjectSummaryDto>>()

  const { partyId } = useOrganizationContext()

  const ShowException = useShowException('kanban')

  const isFirstRender = useRef<boolean>(true)

  const [isConnected, setIsConnected] = useState<boolean>(false)

  const currentPageIndexPerStatus: Partial<Record<ProjectStateDto, number>> =
    useMemo(() => {
      return {
        [ProjectStateDto.Defining]: 0,
        [ProjectStateDto.Requested]: 0,
        [ProjectStateDto.Quoting]: 0,
        [ProjectStateDto.Quoted]: 0,
        [ProjectStateDto.Negotiating]: 0,
        [ProjectStateDto.Ordered]: 0,
        [ProjectStateDto.Producing]: 0,
        [ProjectStateDto.Produced]: 0,
        [ProjectStateDto.Packaging]: 0,
        [ProjectStateDto.Packaged]: 0,
        [ProjectStateDto.Delivering]: 0,
        [ProjectStateDto.Delivered]: 0,
        [ProjectStateDto.Cancelled]: 0,
      }
    }, [])

  const { controller } = useAppController(
    () => new ProjectsSummariesController(),
    {
      disableSubscribeToUpdates: true,
    }
  )

  const { controller: appEventsController } = useAppController(
    () => new AppEventsController(),
    { disableSubscribeToUpdates: true }
  )

  const trackProjectListOpened = useCallback(
    async (success: boolean) => {
      try {
        await appEventsController.PostEvent({
          eventType: 'ProjectsListed',
          eventData: {
            success,
          },
        })
      } catch (err) {
        console.error('unable to track ProjectsListed event', err)
      }
    },
    [appEventsController]
  )

  const fetchProjectList = useCallback(
    async (
      projectState: ProjectStateDto,
      onlyMyProjects: boolean,
      showCanceledProjects: boolean
    ) => {
      try {
        if (!projectState) return null

        controller.CancelRequests()

        const stateString =
          projectState[0]?.toUpperCase() + projectState?.slice(1)

        if (!stateString) {
          return null
        }

        const searchInput = document.getElementById(
          'search-projects'
        ) as HTMLInputElement

        const searchInputValue = searchInput?.value

        const list = await controller.GetProjectsListPaged(
          onlyMyProjects,
          showCanceledProjects,
          {
            page: currentPageIndexPerStatus[stateString],
            pageSize: KANBAN_COLUMN_PAGE_SIZE,
            search: searchInputValue,
            orderBy: 'lastOperation',
            orderDirection: 'desc',
          },
          [projectState]
        )

        return list
      } catch (err) {
        ShowException(err)
        if (isFirstRender.current) {
          trackProjectListOpened(false)
        }

        return null
      }
    },
    [
      ShowException,
      controller,
      currentPageIndexPerStatus,
      trackProjectListOpened,
    ]
  )

  const getCards = useCallback(
    (
      projectList: ProjectSummaryDto[],
      status: string,
      page: number
    ): ProjectCard[] => {
      if (!projectList.length) {
        return []
      }

      return (
        projectList
          ?.filter((x) => x.status?.toLowerCase() === status?.toLowerCase())
          // .sort((x) => x.priority)
          .map((x: ProjectSummaryDto) => ({
            id: x.id,
            title: x.projectReference,
            description: JSON.stringify(x),
            label: x.orderNumber,
            page: page ?? 0,
            metadata: {
              ...x,
              page: page || 0,
            },
          }))
      )
    },
    []
  )

  const getProjectListAndLanes = useCallback(
    async (
      projectStates: Partial<ProjectStateDto>[] = kanbanProjectStates,
      onlyMyProjects: boolean,
      showCanceledProjects: boolean,
      requestedPage?: number
    ): Promise<{
      lanes: ReactTrello.BoardData<ProjectSummaryDto>
    }> => {
      try {
        if (requestedPage !== undefined) {
          projectStates?.forEach((state) => {
            currentPageIndexPerStatus[state] = requestedPage
          })
        }

        const resps = await Promise.all([
          ...projectStates.map((state) =>
            fetchProjectList(state, onlyMyProjects, showCanceledProjects)
          ),
        ])

        const newLanes: ReactTrello.BoardData<ProjectSummaryDto>['lanes'] = []

        for (let i = 0; i < projectStates.length; i++) {
          const status = projectStates[i]

          if (!status) {
            continue
          }

          currentPageIndexPerStatus[status] = resps[i]?.page || 0

          let cards = []

          if (resps[i]) {
            cards = getCards(
              resps[i].details,
              status,
              currentPageIndexPerStatus[status]
            )
          }

          const newLane = {
            id: status,
            laneId: status,
            title: t(`common:${status.toLowerCase()}`),
            label: resps[i]?.totalCount || 0,
            cards: cards,
          } as ReactTrello.Lane<ProjectSummaryDto>

          newLanes.push(newLane)
        }

        return {
          lanes: { lanes: newLanes },
        }
      } catch (err) {
        ShowException(err)
        return {
          lanes: { lanes: lanes?.lanes } || { lanes: [] },
        }
      }
    },
    [
      ShowException,
      currentPageIndexPerStatus,
      fetchProjectList,
      getCards,
      lanes?.lanes,
      t,
    ]
  )

  const updateProjectStatus = useCallback(
    async (
      projectId: string,
      initialStatus: string,
      targetStatus: string,
      position: number
    ) => {
      try {
        await controller.UpdateProjectStatus(
          projectId,
          initialStatus,
          targetStatus,
          position
        )
      } catch (err) {
        throw err
      }
    },
    [controller]
  )

  const handleSetPriority = useCallback(
    async (laneId: ProjectStateDto, projectId: string, priority: number) => {
      let currentProject = null
      try {
        currentProject = lanes.lanes
          .flatMap((x) => x.cards)
          ?.find((x) => x.id === projectId)?.metadata

        const newProject = { ...currentProject }

        newProject.priority = priority

        const newCard = getCards(
          [newProject],
          laneId,
          currentPageIndexPerStatus[laneId]
        )[0]

        eventBus.current?.publish({
          type: 'UPDATE_CARD',
          laneId: laneId,
          card: newCard,
        })

        await controller.UpdateProjectPriority(projectId, priority)

        const newLanes = [...lanes?.lanes]

        newLanes.forEach((lane) => {
          if (lane.id === laneId) {
            lane.cards = lane.cards?.map((card) => {
              return card.id === projectId ? newCard : card
            })
          }
        })

        setLanes({ lanes: newLanes })

        eventBus.current?.publish({
          type: 'REFRESH_BOARD',
          data: { lanes: newLanes },
        })

        // setLanes((l) => {
        //   return {
        //     lanes: l.lanes.map((lane) => {
        //       return {
        //         ...lane,
        //         cards: lane.cards.map((card) => {
        //           return card.id === projectId
        //             ? {
        //                 ...card,
        //                 metadata: {
        //                   ...card.metadata,
        //                   priority: priority,
        //                 },
        //               }
        //             : card
        //         }),
        //       }
        //     }),
        //   }
        // })
      } catch (err) {
        ShowException(err)
        // if (currentProject) {
        //   setProjectList((p) =>
        //     p.map((x) => (x.id === projectId ? currentProject : x))
        //   )
        // }
      }
      // setProjectList((projects) => {
      //   return projects
      //     ?.map((project) =>j
      //       project.id === projectId
      //         ? {
      //             ...project,
      //             priority: priority,
      //             lastOperation: new Date(),
      //           }
      //         : project
      //     )
      //     .sort(
      //       (a, b) =>
      //         new Date(b.lastOperation).getTime() -
      //         new Date(a.lastOperation).getTime()
      //     )
      // })
    },
    [
      ShowException,
      controller,
      currentPageIndexPerStatus,
      getCards,
      lanes?.lanes,
    ]
  )

  const updateData = useCallback(
    async (
      projectStates: typeof kanbanProjectStates,
      myProjects: boolean,
      showCanceled: boolean
    ) => {
      const { lanes } = await getProjectListAndLanes(
        projectStates,
        myProjects,
        showCanceled
      )

      lanes.lanes.forEach((lane) => {
        if (lane.cards.length < KANBAN_COLUMN_PAGE_SIZE) {
          setTimeout(() => {
            //this timeout is needed
            dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
              error: undefined,
              isLastPage: true,
              lastPageFetched: currentPageIndexPerStatus[lane.id],
              projectState: lane.id as ProjectStateDto,
              success: true,
            })
          }, 200)
        }
      })

      setLanes(lanes)
    },
    [currentPageIndexPerStatus, getProjectListAndLanes]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      updateData(kanbanProjectStates, onlyMyProjects, showCanceledProjects)

      if (isFirstRender) {
        trackProjectListOpened(true)
        isFirstRender.current = false
      }
    }
  }, [
    onlyMyProjects,
    showCanceledProjects,
    trackProjectListOpened,
    getProjectListAndLanes,
    updateData,
  ])

  const connectToUpdates = useCallback(async () => {
    const hub = await SignalRService.GetHub()

    hub.unregister('onProjectsListUpdates')
    hub.unregister(`onentergroup-${getGroupName(partyId)}`)

    hub.registerHandler(`onentergroup-${getGroupName(partyId)}`, () => {
      console.info('connected to project updates for the kanban board')
    })

    const debouncedGetProjectListAndLanes = debounceByArgs(
      getProjectListAndLanes,
      2000,
      { leading: true, trailing: true, maxWait: 10_000 }
    )

    hub.registerHandler(
      'onProjectsListUpdates',
      async (statesToUpdate: number[]) => {
        try {
          const onlyMyProjects = (
            document.getElementById('only-my-projects') as HTMLInputElement
          )?.checked
          const showCanceledProjects = (
            document.getElementById(
              'show-canceled-projects'
            ) as HTMLInputElement
          )?.checked

          let newLanes: ReactTrello.BoardData<ProjectSummaryDto> = undefined

          // should only update the states that are in the first page.
          let states: ProjectStateDto[]

          if (statesToUpdate.length === 0) {
            states = kanbanProjectStates
          } else {
            states = statesToUpdate.map((x) => kanbanProjectStates[x - 1])
          }

          states = states.filter((x) => currentPageIndexPerStatus[x] === 0)

          if (states.length === 0) {
            return
          }

          newLanes = (
            await debouncedGetProjectListAndLanes(
              states,
              onlyMyProjects,
              showCanceledProjects
            )
          )?.lanes

          newLanes.lanes.forEach((lane) => {
            eventBus.current?.publish({
              type: 'UPDATE_LANE',
              lane: lane,
            })
          })

          setLanes((curr) => {
            if (!curr.lanes) {
              newLanes
            }

            return {
              lanes: curr.lanes.map((lane) => {
                const newLane = newLanes.lanes.find((x) => x.id === lane.id)
                return newLane || lane
              }),
            }
          })
        } catch (err) {
          ShowException(err)
        }
      }
    )

    hub.JoinGroup(getGroupName(partyId), () => {
      setIsConnected(true)
    })
  }, [
    partyId,
    getProjectListAndLanes,
    currentPageIndexPerStatus,
    ShowException,
  ])

  const disconnectFromUpdates = useCallback(async () => {
    const hub = await SignalRService.GetHub()
    hub.unregister('onProjectsListUpdates')
    hub.LeaveGroup(getGroupName(partyId), () => noop)
  }, [partyId])

  useEffect(() => {
    connectToUpdates()

    return () => {
      disconnectFromUpdates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const signal = subscribeToKanbanEvents('FETCH_NEXT_PAGE', async (e) => {
      const projectState = e.status

      try {
        const projectStateString =
          projectState[0]?.toUpperCase() + projectState.slice(1)

        if (e.forcePage === undefined) {
          currentPageIndexPerStatus[projectStateString] =
            currentPageIndexPerStatus[projectStateString] + 1
        } else {
          currentPageIndexPerStatus[projectStateString] = e.forcePage
        }

        // const resp = await fetchProjectList(
        //   projectState,
        //   onlyMyProjects,
        //   showCanceledProjects
        // )

        // if (!resp || !resp.details.length) {
        //   --currentPageIndexPerStatus[projectStateString]

        //   dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
        //     projectState: projectState,
        //     success: true,
        //     error: undefined,
        //     isLastPage: true,
        //     lastPageFetched: currentPageIndexPerStatus[projectStateString],
        //   })
        //   return
        // }

        const { lanes: updatedLanes } = await getProjectListAndLanes(
          [projectState],
          onlyMyProjects,
          showCanceledProjects
        )

        if (!updatedLanes.lanes[0].cards.length) {
          --currentPageIndexPerStatus[projectStateString]

          dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
            projectState: projectState,
            success: true,
            error: undefined,
            isLastPage: true,
            lastPageFetched: currentPageIndexPerStatus[projectStateString],
          })
          return
        }

        if (currentPageIndexPerStatus[projectStateString] === 0) {
          const allLanes = lanes?.lanes.map((x) => {
            if (x.laneId === projectState) {
              return updatedLanes.lanes[0]
            } else {
              return x
            }
          })

          setLanes((curr) => {
            return {
              lanes: curr.lanes.map((lane) => {
                if (lane.id === projectState) {
                  return {
                    ...lane,
                    cards: updatedLanes.lanes[0].cards,
                  }
                }
                return lane
              }),
            }
          })

          eventBus.current?.publish({
            type: 'REFRESH_BOARD',
            data: { lanes: allLanes },
          })
        } else {
          updatedLanes.lanes[0].cards.forEach((newCard) => {
            eventBus.current?.publish({
              type: 'ADD_CARD',
              laneId: projectState,
              card: newCard,
            })
          })
        }

        dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
          projectState: projectState,
          success: true,
          error: undefined,
          isLastPage:
            updatedLanes.lanes[0].cards.length < KANBAN_COLUMN_PAGE_SIZE,
          lastPageFetched: currentPageIndexPerStatus[projectStateString],
        })
      } catch (err) {
        currentPageIndexPerStatus[projectState] =
          currentPageIndexPerStatus[projectState] - 1

        dispatchKanbanEvents('FETCH_NEXT_PAGE_ENDED', {
          projectState: projectState,
          success: false,
          error: err as Error,
          isLastPage: false,
          lastPageFetched: currentPageIndexPerStatus[projectState],
        })
      }
    })

    return () => {
      signal.abort()
    }
  }, [
    currentPageIndexPerStatus,
    fetchProjectList,
    getCards,
    getProjectListAndLanes,
    lanes?.lanes,
    onlyMyProjects,
    showCanceledProjects,
  ])

  const eventBus = useRef<ReactTrello.EventBus>()

  const setEventBus = useCallback((ev: ReactTrello.EventBus) => {
    eventBus.current = ev
  }, [])

  const resetPagination = useCallback(() => {
    Object.keys(currentPageIndexPerStatus).forEach((key) => {
      currentPageIndexPerStatus[key] = 0
    })
  }, [currentPageIndexPerStatus])

  return {
    onlyMyProjects,
    setOnlyMyProjects,
    setShowCanceledProjects,
    fetchProjectList,
    updateProjectStatus,
    connectToUpdates,
    disconnectFromUpdates,
    isConnected,
    groupName: getGroupName(partyId),
    handleSetPriority,
    showCanceledProjects,
    kanbanProjectStates: kanbanProjectStates,
    getProjectListAndLanes,
    // loading,
    lanes,
    boardRef,
    eventBus,
    setEventBus,
    resetPagination,
    setLanes,
  }
}
