import { useTheme } from '@mui/material'
import { RenderLegend } from 'features/BomItemDetailsDrawer/Graph/RenderLegend'
import { TargetPriceTooltip } from 'features/BomItemDetailsDrawer/Graph/Tooltips/TargetPriceTooltip'
import { PriceDetailsGraphData } from 'features/BomItemDetailsDrawer/useBomItemDetails'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { MultiplyMoney } from 'model/Money'
import { MouseEvent, useCallback, useRef } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from 'recharts'
import { PriceScope } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { MoneyAxisTick } from '../../../Graph/YAxisTick'

const MaterialXAxisTick = (
  props: {
    onMouseEnter: (e: MouseEvent, index: number) => void
    onMouseLeave: (e: MouseEvent) => void
    onClick: (index: number) => void
    payload: { index: number; value: string }
  } & XAxisProps
) => {
  const theme = useTheme()
  const { x, y, payload } = props
  return (
    <g
      transform={`translate(${x},${y})`}
      onClick={() => {
        props.onClick(payload.index)
      }}
      onMouseEnter={(e) => {
        props.onMouseEnter?.(e, payload.index)
      }}
      onMouseLeave={(e) => props.onMouseLeave?.(e)}
      style={{ cursor: 'pointer' }}
    >
      <text
        x={0}
        y={payload.index % 2 === 0 ? 4 : 24}
        textAnchor="middle"
        fill={theme.palette.text.primary}
        fontFamily={theme.typography.fontFamily}
        fontSize={theme.typography.body2.fontSize}
      >
        {payload.value}
      </text>
    </g>
  )
}

export const MaterialsBarChart = (props: {
  priceSummaryKeys: Array<string>
  scrollAndFilter: (priceSummary: PriceDetailsGraphData) => void
  containerHeight?: number
}) => {
  const theme = useTheme()

  const priceSummariesSum: Array<PriceDetailsGraphData> = useAppSelector(
    (state: RootAppState) => {
      const graphData = props.priceSummaryKeys.reduce((acc, key) => {
        const priceSummary = state.project.priceSummaries[key]

        if (!priceSummary?.isMaterial) {
          return acc
        }

        acc.push({
          bomItemPointer: undefined,
          materialId: priceSummary.materialId,
          description: priceSummary.description,
          total: priceSummary.totalSalesPrice,
          mainActivityCostPrice: priceSummary.totalCostPrice,
          surchargeValue: priceSummary.surchargeValue,
          discountValue: MultiplyMoney(priceSummary.discountValue, -1),
          totalQuantity: priceSummary.totalQuantity,
          staggeredTotalQuantity: priceSummary.staggeredTotalQuantity,
          materialDescription: priceSummary.isMaterial
            ? priceSummary.description
            : '',
          materialSalesPrice: priceSummary.totalSalesPrice,
          surchargeRatio: priceSummary.surchargeRatio,
          discountRatio: priceSummary.discountRatio,
          totalCostPrice: priceSummary.totalCostPrice,
          salesPrice: priceSummary.totalSalesPrice,
          totalSalesPrice: priceSummary.totalSalesPrice,
          isMaterial: priceSummary.isMaterial,
          isWorkingStep: priceSummary.isWorkingStep,
          workingStep: priceSummary.workingStep,
        })

        return acc
      }, [] as Array<PriceDetailsGraphData>)

      graphData.length &&
        graphData.sort((a, b) => b.total?.value - a.total?.value)

      return graphData
    },
    isEqual
  )

  const activeDataKey = useRef<string | null>(null)
  const handleChangeDataKey = useCallback(
    (dataKey: keyof PriceDetailsGraphData) => {
      activeDataKey.current = dataKey
    },
    []
  )

  return (
    <>
      <ResponsiveContainer width={'100%'} height={props.containerHeight || 204}>
        <BarChart data={priceSummariesSum} margin={{ bottom: 0 }}>
          <Tooltip
            cursor={false}
            content={(tooltipProps) => {
              return (
                <TargetPriceTooltip
                  {...tooltipProps}
                  activeDataKey={activeDataKey.current}
                  priceScope={PriceScope.Total}
                />
              )
            }}
            wrapperStyle={{
              zIndex: 1000,
            }}
            trigger="click"
            offset={40}
            isAnimationActive={false}
          />

          <defs>
            <pattern
              id="pattern-stripe"
              width="4"
              height="4"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="1"
                height="4"
                transform="translate(0,0)"
                fill="white"
              ></rect>
            </pattern>
            <mask id="mask-stripe">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#pattern-stripe)"
              />
            </mask>
          </defs>
          {/* <Tooltip /> */}
          <CartesianGrid strokeDasharray={'3 3'} />
          <XAxis
            dataKey="materialId"
            type="category"
            interval={0}
            tickMargin={12}
            height={42}
            tick={(_props) => (
              <MaterialXAxisTick
                {..._props}
                onClick={(index) =>
                  props.scrollAndFilter(priceSummariesSum[index])
                }
              />
            )}
          />
          <YAxis
            type="number"
            ticks={priceSummariesSum
              ?.map((x) => x.salesPrice?.value)
              .sort((a, b) => b - a)}
            minTickGap={0}
            tickLine={true}
            interval={'preserveStart'}
            allowDataOverflow={true}
            tick={
              <MoneyAxisTick
                defaultMoney={priceSummariesSum[0].totalCostPrice}
              />
            }
          />

          <Bar
            dataKey="totalCostPrice.value"
            fill={theme.palette.info.dark}
            stackId={'a'}
            barSize={54}
            onMouseEnter={() => handleChangeDataKey('totalCostPrice')}
            onMouseLeave={() => handleChangeDataKey(undefined)}
            name={t('project:cost-price', 'cost price')}
            onClick={(data) => props.scrollAndFilter(data)}
          ></Bar>
          <Bar
            dataKey="surchargeValue.value"
            fill={theme.palette.success.dark}
            stackId={'a'}
            barSize={54}
            name={t('project:surcharge', 'surcharge')}
            style={{
              cursor: 'pointer',
            }}
            onMouseEnter={() => handleChangeDataKey('surchargeValue')}
            onMouseLeave={() => handleChangeDataKey(undefined)}
            onClick={(data) => props.scrollAndFilter(data)}
          ></Bar>
          <Bar
            dataKey="discountValue.value"
            mask="url(#mask-stripe)"
            stackId={'a'}
            barSize={54}
            name={t('project:discount', 'discount')}
            style={{
              cursor: 'pointer',
            }}
            onMouseEnter={() => handleChangeDataKey('discountValue')}
            onMouseLeave={() => handleChangeDataKey(undefined)}
            onClick={(data) => props.scrollAndFilter(data)}
          >
            {priceSummariesSum.map((x, index) => (
              <Cell
                key={`cell-${x.description}-${index}-discountValue`}
                mask={'url(#mask-stripe)'}
                fill={theme.palette.error.main}
                stroke={theme.palette.common.black}
                strokeWidth={2}
                opacity={1}
              />
            ))}
          </Bar>
          <Legend
            content={(legendProps) => <RenderLegend {...legendProps} />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}
