import { Box } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { useTranslation } from 'react-i18next'
import { ErrorCountBadge } from './ErrorCountBadge'
import { LightTooltip } from './LightTooltip'
import { useOrderProjectState } from './useOrderProjectState'
import { useProjectStateItem } from './useProjectStateItem'

const projectState = ProjectStatus.ORDERED
const projectStateAsString = ProjectStateAsString.ORDERED

export const OrderState = () => {
  const {
    getStateTranslationKey,
    stateItemIsCurrentOrPast,
    getBaseClassName,
    changeStatus,
    SavingComponent,
  } = useProjectStateItem(projectState)

  const { t } = useTranslation()

  const { openDialog } = useDialogManager()

  const { canChangeToOrderState, Errors, errorStyle, errorCount } =
    useOrderProjectState()

  const handleClick = () => {
    if (canChangeToOrderState) {
      openDialog('ConfirmationDialog', {
        title: t('project:order-project', 'order project'),
        message: (
          <div>
            {errorCount === 0 ? (
              t(
                'project:order-project-confirmation',
                'Are you sure you want to order this project?'
              )
            ) : (
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  padding: (theme) => theme.spacing(1),
                  display: 'flex',
                  flexDirection: 'column',
                  gap: (theme) => theme.spacing(2),
                }}
              >
                <LocalizedTypography
                  variant="subtitle1"
                  translationKey="project:project-validation"
                >
                  unable to order the project:
                </LocalizedTypography>
                <Errors />
              </Box>
            )}
          </div>
        ),
        disableOkButton: errorCount > 0,
        onConfirm: () => {
          changeStatus(projectStateAsString)
        },
      } as ConfirmationDialogProps)
    }
  }

  return (
    <LightTooltip title={errorCount > 0 ? <Errors /> : ''}>
      <li style={errorStyle} className={getBaseClassName} onClick={handleClick}>
        <LocalizedTypography
          translationKey={getStateTranslationKey()}
          variant="body2"
        />
        <ErrorCountBadge errorCount={errorCount} />
        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
